/**
* 定時集計画面用モジュール。
* @module app/scheduledReport/ScheduledReportMunicListPage
*/
define([
	'module',
	'dojo/_base/declare',
	'dojo/_base/lang',
	'dojo/text!./templates/ScheduledReportMunicListPage.html',
	'idis/view/page/_PageBase',
	'idis/control/Locator',
	'idis/control/Router',
	'idis/store/IdisRest',
	'idis/view/dialog/DialogChain',
	'app/model/DisasterInfo',
	'dojo/when',
	// 以下、変数として受け取らないモジュール
	'dijit/Dialog',
	'dijit/form/Form',
	'dijit/layout/BorderContainer',
	'dijit/layout/ContentPane',
	'idis/view/form/AclButton',
	'idis/view/form/Button',
	'idis/view/form/DateTimeInput',
	'./ScheduledReportFormDialog',
	'./ScheduledReportMunicListGrid'
], function (module, declare, lang, template, _PageBase,
	Locator, Router, IdisRest, DialogChain, DisasterInfo, when) {


	/**
	* 定時集計(各区・局)画面。
	* @class ScheduledReportMunicListPage
	* @extends module:idis/view/page/_PageBase~_PageBase
	*/
	return declare(module.id.replace(/\//g, '.'), _PageBase,
		{
			// テンプレート文字列
			templateString: template,

			// ルート要素に付与されるCSS
			baseClass: 'idis-Page idis-Page--scheduledreport',

			/**
			 * データ格納用オブジェクト
			 * @type {module:dstore/Store}
			 */
			store: null,
			/**
			 * コンストラクタ
			 */
			constructor: function () {

				// 災害IDをDisasterInfoから取得
				this._disasterId = DisasterInfo.getDisasterId();
				// URLから報告番号を取得
				this._scheduledReportId = Locator.getQuery().scheduledReportId;
				this._admNum = Locator.getQuery().admNum;
				// データ格納用オブジェクト
				this.store = new IdisRest({
					idProperty: 'scheduledReportId',
					target: '/api/scheduledReport/munic',
					rangeStartParam: 'offset',
					rangeCountParam: 'count'
				});

				// 入力値を元にグリッド用フィルターを作成
				this.chain = DialogChain.get(this);
			},

			/**
			 * DOM生成
			 */
			// DOMノードを生成するためのメソッド
			buildRendering: function () {
				this.inherited(arguments);
			},

			/**
			 * 画面生成完了
			 */
			startup: function () {
				this.inherited(arguments);
				//集計番号・集計時点を表示
				this.admNum.innerHTML = this._admNum;
				// グリッドを初期化する
				this.initGrid();
			},

			/**
			 * グリッドを初期化する。
			 */
			initGrid: function () {
				// グリッドの詳細ボタンクリック時の動作を設定する
				// helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
				this.grid.on('detailButtonClick', lang.hitch(this, function (evt) {
					this.onDetailButtonClick(evt.item);
				}));


				this.updateGridQuery();
			},


			/**
			 * 検索パラメーターの設定
			 */
			updateGridQuery: function () {

				var filter = new this.store.Filter();
				filter = filter.eq('disasterId', this._disasterId);
				filter = filter.eq('admNum', this._admNum);
				filter = filter.eq('scheduledReportId', this._scheduledReportId);
				when(this.store.filter(filter).fetch()).then(lang.hitch(this, function(data){
					this.aggrTimestamp.innerHTML = '---';
					if(data.slice(-1)[0].aggrTimestamp) {
						this.aggrTimestamp.innerHTML = data.slice(-1)[0].aggrTimestamp;
					}
				}));
				// collectionを取得
				var collection = this.store.filter(filter);

				// collectionをグリッドにセットする（サーバーにリクエストされる）
				this.grid.set('collection', collection);
			},

			/**
			 * 定時集計詳細を表示
			 */
			onDetailButtonClick: function (item) {
				// 被害一覧画面へ遷移
				Router.moveTo('scheduledReport/munic/detail', {
					//被害一覧で区ごとに検索可能にする為、大阪市全体の定時集計レコードのIDをPrefIdとしてセットして送る
					scheduledReportPrefId: this._scheduledReportId,
					scheduledReportId: item.scheduledReportId,
					admNum: this._admNum
				});
			},
			/**
			 * 被害一覧を表示
			 */
			onDamageReportPageLinkClick: function (evt) {
				//検索条件の保管
				this.setFilterStore();
				// ブラウザーの遷移処理をキャンセル
				evt.preventDefault();

				// 被害一覧画面へ遷移
				Router.moveTo('report');
			},
			/**
			 * 定時状況一覧を表示
			 */
			onScheduledReportLinkClick: function (evt) {
				// ブラウザーの遷移処理をキャンセル
				evt.preventDefault();

				// 定時状況一覧へ遷移
				Router.moveTo('scheduledReport');
			},
			onListPageLinkClick: function (evt) {
				// ブラウザーの遷移処理をキャンセル
				evt.preventDefault();
				// 定時状況一覧へ遷移
				Router.moveTo('scheduledReport');
			},

			/**
			 * 帳票出力
			 */
			showReportForm: function () {
				this.reportFormDialog.show();
			}

		});
});
