/**
* 被害報画面用モジュール。
* @module app/damage/DamageReportPage
*/
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/dom-style',
    'dojo/text!./templates/DamageReportPage.html',
    'dojo',
    'dojo/dom',
    'dojo/dom-construct',
    'dojo/topic',
    'dojo/Deferred',
    './_DamageReportPageBase',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/service/Requester',
    'idis/store/IdisRest',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    'idis/view/Loader',
    'idis/view/dialog/DialogChain',
    'idis/map/IdisMap',
    'idis/view/draw/_DrawUtil',
    '../config',
    'idis/consts/USER_TYPE',
    // 以下、変数として受け取らないモジュール
    'dijit/Dialog',
    'dijit/form/CheckBox',
    'dijit/form/Form',
    'dijit/form/TextBox',
    'dijit/form/NumberTextBox',
    'dijit/form/ValidationTextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/form/RadioButton',
    'dojox/form/Uploader',
    'app/view/form/DisasterSelector',
    'app/view/form/OrganizationSelector',
    'app/view/form/CustomMunicipalityMultiSelectorNoPref',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'idis/view/form/RadioGroup',
    './DamageReportGrid',
    'app/view/form/DamageHldOrgMultiSelector',
    'app/damage/damageForm/DamageReportFormDialog',
    'app/damage/DamageReportFileUploadDialog'
], function (module, declare, lang, array, domStyle, template, dojo, dom, domCon, topic, Deferred,
    _DamageReportPageBase, Locator, Router, Requester, IdisRest, UserInfo, UserType, Loader,
    DialogChain, IdisMap, DrawUtil, config, USER_TYPE) {

    /**
    * 検索条件格納用オブジェクト
    * @type {Object}
    */
    var damageFilterStore = {};

    /**
    * 被害報画面。
    * @class DamageReportPage
    * @extends module:idis/view/page/_PageBase~_PageBase
    */
    return declare(module.id.replace(/\//g, '.'), _DamageReportPageBase,
        /** @lends module:app/damage/DamageReportPage~DamageReportPage# */ {
            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--damage',

            /**
             * データ格納用オブジェクト
             * @type {module:dstore/Store}
             */
            store: null,

            //grid中に写真のカラムを表示するか
            displayPicFlg: true,

            //地図上の描画
            drawnDamageIds: [],
            divIconMarkers: [],

            /**
             * 地図を動的に描画・操作するために発行するトピック
             */
            DRAW_ICON: 'app/damage/DamageReportGrid' + '::drawIcon',

            _damageTypeDispList: [],

            // 所属コード
            _deptCd: null,

            constructor: function () {
                //this.inherited(arguments);
                // データ格納用オブジェクト
                this.store = new IdisRest({
                    idProperty: 'damageReportId',
                    target: '/api/damageReports',
                    rangeStartParam: 'offset',
                    rangeCountParam: 'count'
                });
                //地図アイコンに関する情報を初期化
                this.drawnDamageIds = [];
                this.divIconMarkers = [];
            },

            // DOMノードを生成するためのメソッド
            buildRendering: function () {
                this.inherited(arguments);

            },

            startup: function () {

                this.inherited(arguments);

                // 新規登録ボタン・定時報告ボタンの活性/非活性を初期化する
                this.initButtonStatus();

                // 地図を初期化する
                this.initMap(config.map.latitude, config.map.longitude);

                // グリッドが描画された際に地図にも位置情報を描画する
                var drawIconEvent = topic.subscribe(this.DRAW_ICON, lang.hitch(this, function (arg) {
                    this.addMarker(arg);
                }));

                this._events.push(drawIconEvent);

                // 被害の種類一覧設定
                this.initSetDamageTypeList();

                // 初期選択する市町村情報をセット
                this.initMunicipalitySelector();
                this.onMunicipalityChange();
                if (UserInfo.getDamageViewType() === "4") {
                    this.reportStatus.set("value", "41");
                    this.reportStatus.set("disabled", true);
                }

                this.own(topic.subscribe(this.MUNICIPALITY_SELECTED, lang.hitch(this, function (data) {
                    this.setMunicipalityCd(data);
                })));

                this.own(topic.subscribe(this.MUNICIPALITY_RELEASED, lang.hitch(this, function (data) {
                    this.releaseMunicipalityCd(data);
                })));

                this.own(this.municipalityCd);

                // グリッドを初期化する
                this.initGrid();

                // 大阪市：画面自動更新用のタイマーを設定(5分毎)
                this.timer = setInterval(lang.hitch(this, function () {
                    this.updateGridQuery(this.form.get('value'));
                }), 5 * 60 * 1000);

            },

            /**
             * widgetが居なくなる時に呼ばれる
             * 定期処理を止める
             */
            destroy: function () {
                this.inherited(arguments);
                clearInterval(this.timer);
            },

            // 被害の種類のセレクタ一覧を生成
            initSetDamageTypeList: function () {
                var optionList = [{
                    label: '&nbsp;',
                    value: '',
                }];
                this.getDamageTypeList().then(lang.hitch(this, function (itemList) {
                    Object.keys(itemList).forEach(function (id) {

                        optionList.push({
                            value: itemList[id].key,
                            label: itemList[id].val,
                        });
                    });
                    this.damageType.set('options', optionList);
                }));
            },

            selectRecord: function (arg) {
                // 緯度経度がある場合のみ地図を移動させる
                var lat = arg.latitude;
                var lng = arg.longitude;
                if (lat && lng) {
                    this.map.setView([lat, lng]);
                }

                // 画面右下の詳細表示Paneを操作する
                if (this.guideMessage) {
                    domStyle.set(this.guideMessage, 'display', 'none');
                }
                if (this.selectedDamage) {
                    domStyle.set(this.selectedDamage, 'display', '');

                    this.selectedContent.innerHTML = arg.content ? arg.content : '原因/内容は未登録です。';
                    this.selectedHldContent.innerHTML = arg.hldContent ? arg.hldContent : '対応内容は未登録です。';

                    var self = this;
                    if (arg.attachFiles) {
                        this.selectedPicure.innerHTML = '';
                        array.forEach(arg.attachFiles, function (attachFile) {
                            // 画像でないファイルは表示しない
                            var fileName = attachFile.attachFileName;
                            if (fileName.indexOf('.jpg') === -1 && fileName.indexOf('.jpeg') === -1 &&
                                fileName.indexOf('.png') === -1 && fileName.indexOf('.JPG') === -1 &&
                                fileName.indexOf('.JPEG') === -1 && fileName.indexOf('.PNG') === -1 &&
                                fileName.indexOf('.gif') === -1) {
                                return false;
                            }
                            var path = attachFile.attachFilePath.replace('out', 'data');
                            var img = '<img src="' + path + '" alt="画像を読み込めません。" style="width: 100px">';
                            var dom = domCon.toDom(img);
                            domCon.place(dom, self.selectedPicure);
                        });
                    }
                    if (!this.selectedPicure.innerHTML) {
                        this.selectedPicure.innerHTML = '表示する写真はありません。';
                    }
                }
            },


            // TODO:要確認 新規登録ボタンについては、FUNC_CDとは別に「関係機関は表示しない」という暫定措置をとる
            initButtonStatus: function () {
                if (UserInfo.getUserType() === UserType.OTHER_ORGAN) {
                    domStyle.set(this.registerButton, 'display', 'none');
                }
                // 消防局または、管理ユーザのみ消防局一括登録可能
                if (this.fireFileUploadButton !== undefined) {
                    if (UserInfo.getOrganization().deptCd === config.dept.fireDepartmentDeptCd || UserInfo.isSysAdmin()) {
                        this.fireFileUploadButton.set('style', { display: '' });
                    } else {
                        this.fireFileUploadButton.set('style', { display: 'none' });
                    }
                }
                //閲覧メニューから開いた被害情報一覧では定時集計ボタンを表示しない
                if (Locator.getQuery().p.indexOf('view') !== -1) {
                    domStyle.set(this.reportButton.domNode, 'display', 'none');
                //定時集計機能に閲覧権限があるユーザは定時集計ボタンを押下可能
                } else if (UserInfo.hasWriteAuthz('F05009') || UserInfo.hasReadAuthz('F05009')) {
                    domStyle.set(this.reportButton.domNode, 'display', '');
                } else {
                    domStyle.set(this.reportButton.domNode, 'display', 'none');
                }
            },

            /**
             * 一覧画面に初期表示する市町村のコードを取得する。
             *
             */
            initMunicipalitySelector: function () {

                if (Locator.getQuery().municipalityCd) {
                    // 概況画面から遷移した場合は、選択されていた市町村を設定する。
                    this._municipalityCd = Locator.getQuery().municipalityCd;
                } else if (UserInfo.getUserType() === UserType.MUNICIPALITY) {
                    // 市町ユーザの場合、自分の市町を設定
                    this._municipalityCd = UserInfo.getMunicipalityCd();
                } else if (UserInfo.getUserType() === UserType.OTHER_ORGAN) {
                    // 市町ユーザの場合、自分の市町を設定
                    this._municipalityCd = UserInfo.getMunicipalityCd();
                }
                if (this._municipalityCd === config.municInfo.cityMunicCd) {
                    this._municipalityCd = '';
                }
                console.log('Init Munic : ' + this._municipalityCd);
                //市町セレクタがある場合、初期表示を設定する。
                if (this.municipalityCd && this._municipalityCd) {
                    this.municipalityCd.value[0] = this._municipalityCd;
                }

                // 大阪市：区役所ユーザは区の変更が不可
                if (UserInfo.getApprovalType() !== "1") {
                    this.overviewLabel.style.display = 'none';
                }
            },

            /**
             * 一覧画面に初期表示する所属のコードを取得する。
             *
             */
            initOrganizationSelector: function () {

                if (Locator.getQuery().deptCd) {
                    // 選択されていた所属コードを設定する。
                    this._deptCd = Locator.getQuery().deptCd;
                } if (UserInfo.getMunicipalityCd() === config.municInfo.cityMunicCd) {
                    // 市町ユーザの場合、自分の市町を設定
                    this._deptCd = UserInfo.getDeptCd();
                }
                console.log('Init deptCd : ' + this._deptCd);
                //市町セレクタがある場合、初期表示を設定する。
                if (this._deptCd) {
                    this.reportOrganization.set('value', ['D' + this._deptCd]);
                }

            },

            /**
             * グリッドを初期化する。
             */
            initGrid: function () {
                // グリッドの詳細ボタンクリック時の動作を設定する
                // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
                this.damageReportGrid.on('detailButtonClick', lang.hitch(this, function (evt) {
                    this.onDetailButtonClick(evt.item);
                }));

                // グリッドをクリックした際の処理
                this.damageReportGrid.on('dgrid-select', lang.hitch(this, function (evt) {
                    this.selectRecord(evt.rows[0].data);
                }));

                //添付ファイルリンクを押下した場合
                this.damageReportGrid.on('attachFileLinkClick', lang.hitch(this, function (evt) {
                    var attachFilePath = evt.item.attachFilePath.replace('out', 'data');
                    var url = location.protocol + '//' + location.hostname + ':' + location.port + '/' + attachFilePath;
                    this.downloadFile(url, evt.item.attachFileName);
                }));

                // 前画面が概要以外ページの場合
                console.log(Locator.getPrevPage());
                if (!!Locator.getPrevPage() && Locator.getPrevPage().indexOf('report/admin') === -1) {
                    // 保管した検索条件をセットする
                    this.setFilterData();
                }
                this.updateGridQuery(this.form.get('value'));
            },

            /**
             * マップを初期化する。
             */
            initMap: function (latitude, longitude) {
                var latlng = [latitude, longitude];

                this.map = new IdisMap(this.mapNode, {
                    config: config.map,
                    keyboard: false, // コメント時に+/-が使用できないため
                    touchExtend: false,
                    minZoom: 9,
                    maxZoom: 18,
                    drawControlTooltips: false
                }
                ).setView(latlng, 14);
                // destroy時にmapを破棄するよう設定
                this.own(this.map);

            },

            /**
             * 市町村コードを再セットする。
             */
            onMunicipalityChange: function () {
                console.debug('行政区が変更されました');
                this._municipalityCd = this.municipalityCd.get('value');
            },

            /**
             * 検索
             */
            onSubmit: function () {
                try {
                    if (this.form.validate()) {
                        // 入力値が正常ならグリッドの検索条件を更新
                        this.updateGridQuery(this.form.get('value'));
                    }
                } catch (e) {
                    console.error(e);
                }
                return false;
            },

            /**
             * 検索パラメーターの設定
             */
            updateGridQuery: function (value) {

                this.removeMarkers();
                // 入力値を元にグリッド用フィルターを作成
                var filter = new this.store.Filter();

                // 災害ID
                filter = filter.eq('disasterId', this._disasterId);

                // 市町村コード
                if (this.municipalityCd.value) {
                    filter = filter.eq('municipalityCd', this.municipalityCd.value.join(','));
                }

                // 報告者組織
                if (this.reportOrganization.value) {
                    filter = filter.eq('reportOrganization', this.reportOrganization.value.join(','));
                }

                // キーワード
                if (value.keyword) {
                    filter = filter.eq('keyword', value.keyword);
                }

                // 検索対象市町村に対して権限があるかどうか
                // var hasAuthForMunic = false;
                // if (UserInfo.getUserType() === USER_TYPE.MUNICIPALITY) {
                //     if (!this.municipalityCd.value) {
                //         // 市町村ユーザで、市町村が「全選択」であれば、問答無用で「権限なし」
                //         // FIXME: 本当は、自分の市町村の情報だけは全情報出さなければいけない
                //         hasAuthForMunic = false;
                //     } else if (UserInfo.getMunicipalityCd() === this.municipalityCd.value) {
                //         // 自分の市町村であれば、権限あり
                //         hasAuthForMunic = true;
                //     } else if (UserInfo.getMunicipalityCd() === config.municInfo.cityMunicCd &&
                //         this.municipalityCd.value.substr(0, 4) === config.municInfo.cityMunicCd.substr(0, 4)) {
                //         // 政令指定都市ユーザの場合、市町村コードの最初４桁があっていれば、市内の区であるので、権限ありとする
                //         hasAuthForMunic = true;
                //     }

                // }

                if (value.reportStatus) {
                    filter = filter.eq('reportStatus', value.reportStatus);
                }
                if (UserInfo.getDamageViewType() === "4") {
                    filter = filter.eq('reportStatus', "41");
                }

                // 被害種別
                if (value.damageType) {
                    filter = filter.eq('damageType', value.damageType);
                }

                // 緊急度
                if (value.urgencyType) {
                    filter = filter.eq('urgencyType', value.urgencyType);
                }

                // 管理番号
                if (value.admNum) {
                    filter = filter.eq('admNum', value.admNum);
                }

                // 子被害表示
                if (value.childReport.length === 0) {
                    filter = filter.eq('hasNoParentFlg', '1');
                } else {
                    filter = filter.eq('hasNoParentFlg', '0');
                }

                // 報告日時FROM
                if (value.reportCrtDateTimeFrom) {
                    var reportCrtDateTimeFrom = new Date(value.reportCrtDateTimeFrom).getTime();
                    filter = filter.eq('reportCrtDateTimeFrom', reportCrtDateTimeFrom);
                }

                // 報告日時TO
                if (value.reportCrtDateTimeTo) {
                    var reportCrtDateTimeTo = new Date(value.reportCrtDateTimeTo).getTime();
                    filter = filter.eq('reportCrtDateTimeTo', reportCrtDateTimeTo);
                }

                // 更新日時FROM
                if (value.reportUpdDateTimeFrom) {
                    var reportUpdDateTimeFrom = new Date(value.reportUpdDateTimeFrom).getTime();
                    filter = filter.eq('reportUpdDateTimeFrom', reportUpdDateTimeFrom);
                }

                // 更新日時TO
                if (value.reportUpdDateTimeTo) {
                    var reportUpdDateTimeTo = new Date(value.reportUpdDateTimeTo).getTime();
                    filter = filter.eq('reportUpdDateTimeTo', reportUpdDateTimeTo);
                }

                // 過去報表示
                if (value.pastReport.length === 0) {
                    filter = filter.eq('activeFlg', '1');
                } else {
                    filter = filter.eq('activeFlg', '0');
                }

                // 子被害表示
                if (value.deletedReport.length !== 0) {
                    filter = filter.eq('deletedReportFlg', '1');
                }

                // 人的被害あり
                if (value.humanDamageFlg.length !== 0) {
                    filter = filter.eq('humanDamageFlg', '0');
                }

                // filterに対応するcollectionを取得
                var collection = this.store.filter(filter);

                // collectionをグリッドにセットする（サーバーにリクエストされる）
                this.damageReportGrid.set('collection', collection);

            },

            onOpenFilterClick: function () {
                this.openingDetailFilter = true;
                domStyle.set(this.openFilterLink, 'display', 'none');
                domStyle.set(this.closeFilterLink, 'display', '');
                domStyle.set(this.reportTimeForm, 'display', '');
                domStyle.set(this.updTimeForm, 'display', '');
                domStyle.set(this.aboveSearchButtonDiv, 'display', 'none');
                this.borderContainer.resize();
            },

            onCloseFilterClick: function () {
                this.openingDetailFilter = false;
                domStyle.set(this.openFilterLink, 'display', '');
                domStyle.set(this.closeFilterLink, 'display', 'none');
                domStyle.set(this.reportTimeForm, 'display', 'none');
                domStyle.set(this.updTimeForm, 'display', 'none');
                domStyle.set(this.aboveSearchButtonDiv, 'display', '');
                this.borderContainer.resize();
            },

            /**
             * 検索条件を保管する
             */
            setFilterStore: function () {
                //初期化する
                damageFilterStore = {};
                var value = this.form.get('value');

                // 報告日時FROM
                if (value.reportCrtDateTimeFrom) {
                    damageFilterStore.reportCrtDateTimeFrom = value.reportCrtDateTimeFrom;
                }

                // 報告日時TO
                if (value.reportCrtDateTimeTo) {
                    damageFilterStore.reportCrtDateTimeTo = value.reportCrtDateTimeTo;
                }

                // 更新日時FROM
                if (value.reportUpdDateTimeFrom) {
                    damageFilterStore.reportUpdDateTimeFrom = value.reportUpdDateTimeFrom;
                }

                // 更新日時TO
                if (value.reportUpdDateTimeTo) {
                    damageFilterStore.reportUpdDateTimeTo = value.reportUpdDateTimeTo;
                }

                // 過去報表示
                if (value.pastReport.length > 0) {
                    damageFilterStore.pastReport = 'checked';
                }

                // 被害種別
                if (value.damageType) {
                    damageFilterStore.damageType = value.damageType;
                }

                // 対応状況
                if (value.hldStatus) {
                    damageFilterStore.hldStatus = value.hldStatus;
                }

                // 緊急度
                if (value.urgencyType) {
                    damageFilterStore.urgencyType = value.urgencyType;
                }

                // 市町村コード
                if (value.municipalityCd) {
                    damageFilterStore.municipalityCd = value.municipalityCd;
                }

                // 対応課
                if (value.reportOrganization) {
                    damageFilterStore.reportOrganization = value.reportOrganization;
                }

                // キーワード
                if (value.keyword) {
                    damageFilterStore.keyword = value.keyword;
                }

                // 報告状況
                if (value.reportStatus) {
                    damageFilterStore.reportStatus = value.reportStatus;
                }

                // 管理番号
                if (value.admNum) {
                    damageFilterStore.admNum = value.admNum;
                }

                // 子被害表示
                if (value.childReport.length > 0) {
                    damageFilterStore.childReport = 'checked';
                }

                // 削除済み被害表示
                if (value.deletedReport.length > 0) {
                    damageFilterStore.deletedReport = 'checked';
                }

                // 人的被害あり
                if (value.humanDamageFlg.length > 0) {
                    damageFilterStore.humanDamageFlg = 'checked';
                }

                // 詳細検索項目を開いているかを保存する
                damageFilterStore.openingDetailFilter = this.openingDetailFilter;

            },

            /**
             * 保管した検索条件をフォームにセットする
             */
            setFilterData: function () {
                //保管されていれば値をセット

                // 報告日時FROM
                if (damageFilterStore.reportCrtDateTimeFrom) {
                    this.reportCrtDateTimeFrom.set('value', damageFilterStore.reportCrtDateTimeFrom);
                }

                // 報告日時TO
                if (damageFilterStore.reportCrtDateTimeTo) {
                    this.reportCrtDateTimeTo.set('value', damageFilterStore.reportCrtDateTimeTo);
                }

                // 更新日時FROM
                if (damageFilterStore.reportUpdDateTimeFrom) {
                    this.reportUpdDateTimeFrom.set('value', damageFilterStore.reportUpdDateTimeFrom);
                }

                // 更新日時TO
                if (damageFilterStore.reportUpdDateTimeTo) {
                    this.reportUpdDateTimeTo.set('value', damageFilterStore.reportUpdDateTimeTo);
                }

                // 過去報表示
                if (damageFilterStore.pastReport) {
                    this.pastReport.set('checked', 'checked');
                }

                // 被害種別
                if (damageFilterStore.damageType) {
                    this.damageType.set('value', damageFilterStore.damageType);
                }

                // 対応状況
                if (damageFilterStore.hldStatus) {
                    this.hldStatus.set('value', damageFilterStore.hldStatus);
                }

                // 緊急度
                if (damageFilterStore.urgencyType) {
                    this.urgencyType.set('value', damageFilterStore.urgencyType);
                }

                // 市町村コード
                if (damageFilterStore.municipalityCd) {
                    this.municipalityCd.set('value', damageFilterStore.municipalityCd);
                }

                // 対応課
                if (damageFilterStore.reportOrganization) {
                    this.reportOrganization.set('value', damageFilterStore.reportOrganization);
                }

                // 報告状況
                if (damageFilterStore.reportStatus) {
                    this.reportStatus.set('value', damageFilterStore.reportStatus);
                }

                // キーワード
                if (damageFilterStore.keyword) {
                    this.keyword.set('value', damageFilterStore.keyword);
                }

                // 管理番号
                if (damageFilterStore.admNum) {
                    this.admNum.set('value', damageFilterStore.admNum);
                }

                // 子被害表示
                if (damageFilterStore.childReport) {
                    this.childReport.set('checked', 'checked');
                }

                // 削除済み被害表示
                if (damageFilterStore.deletedReport) {
                    this.deletedReport.set('checked', 'checked');
                }

                // 人的被害あり
                if (damageFilterStore.humanDamageFlg) {
                    this.humanDamageFlg.set('checked', 'checked');
                }

                //詳細検索フォームを開いているかどうか
                if (damageFilterStore.openingDetailFilter) {
                    this.onOpenFilterClick();
                }
            },

            /**
             * 新規登録画面を表示
             */
            showRegisterPage: function () {
                //検索条件の保管
                this.setFilterStore();
                this._removeEvents();

                //新規画面に移動
                var municipalityCd = this.municipalityCd.value;

                if(municipalityCd.length === 1){
                    municipalityCd = municipalityCd.toString();
                    if (municipalityCd && 
                        municipalityCd.substring(0, 3) !== config.municInfo.prefRegCdGudge &&
                        municipalityCd !== config.municInfo.cityMunicCd) {
                        Router.moveTo('report/register', { municipalityCd: municipalityCd });
                    } else {
                        Router.moveTo('report/register');
                    }
                }else{
                    Router.moveTo('report/register');
                }

            },

            /**
             * 詳細画面を表示
             */
            onDetailButtonClick: function (object) {
                //検索条件の保管
                this.setFilterStore();

                this._removeEvents();
                // 被害状況・報告詳細画面へ遷移
                Router.moveTo('report/detail', {
                    damageReportId: object.damageReportId
                });
            },

            /**
             * 被害状況概況一覧画面へ遷移する。（フィルタの保存が必要なので独自に定義）
             */
            onDamageReportAdminPageLinkClick: function (evt) {
                //検索条件の保管
                this.setFilterStore();

                this._removeEvents();
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                Router.moveTo('report/admin');
            },

            /**
             * 被害情報管理：集計表示に遷移する
             */
            onDamageReportAggregatePageBtnClick: function (evt) {
                //検索条件の保管
                this.setFilterStore();

                this._removeEvents();
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                Router.moveTo('report/aggregate', { municipalityCd: this.municipalityCd.value });
            },
            /**
             * 消防局一括登録
             */
            showFireFileUploadDialog: function () {
                this.fireFileUploadDialog.show();
            },

            /**
             * grid描画時、被害情報の位置情報をもとに地図にもプロットする
             */
            addMarker: function (arg) {
                // すでに描画されていた場合は戻る。
                //if(this.drawnDamageIds.includes(arg.damageReportId)){
                if (this.drawnDamageIds.indexOf(arg.damageReportId) !== -1) {
                    return false;
                }

                var marker = this.makeDamageMarker(arg);
                if (marker) {
                    var self = this;
                    marker.addTo(this.map).on('click', function () {
                        self.clickMarker(arg);
                    });
                    this.divIconMarkers.push(marker);
                    this.drawnDamageIds.push(arg.damageReportId);
                }
            },

            //地図上のマーカー押下時の挙動
            clickMarker: function (arg) {
                this.damageReportGrid.clearSelection();
                this.damageReportGrid.select(arg.damageReportId);
            },

            /**
             * 地図上のアイコンを削除する
             */
            removeMarkers: function () {
                var map = this.map;
                array.forEach(this.divIconMarkers, function (divIcon) {
                    map.removeLayer(divIcon);
                });

                this.drawnDamageIds.length = 0;
            },

            /**
             * 被害の種類のセレクタ一覧を生成
             */
            getDamageTypeList: function () {
                var deferred = new Deferred();
                if (this._damageTypeDispList.length > 0) {
                    return deferred.resolve(this._damageTypeDispList);
                }
                return Loader.wait(Requester.get('api/code/list?type=DAMAGE_TYPE&display=true')
                    .then(lang.hitch(this, function (data) {
                        data.forEach(lang.hitch(this, function (item) {
                            this._damageTypeDispList[item.sort] = {
                                key: item.key,
                                val: item.value
                            };
                        }));
                        return this._damageTypeDispList;
                    }))
                );
            },
            /**
             * 帳票出力
             */
            showDamageForm: function () {
                var dialog = this.damageFormDialog;
                var page = dialog.getChildren()[0];
                var municipalityCd = this._municipalityCd;
                var deptCd = this._deptCd;
                var disasterId = this._disasterId;
                page.setInitValue(municipalityCd, deptCd, disasterId);

                this.damageFormDialog.show();
            }
        });
});
