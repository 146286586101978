define([
	'module',
	'dojo/_base/declare',
	'dojo/on',
	'idis/view/grid/IdisGrid',
	'idis/view/grid/helper',
	'idis/view/form/Button',
	'app/config'
], function (module, declare, on, IdisGrid, helper, Button, config) {
	
	//市の市町コード
	var PREF_MUNICIPALITY_CODE = config.municInfo.prefMunicCd;

	return declare(module.id.replace(/\//g, '.'), IdisGrid, {

		// ルート要素に付与されるCSS
		baseClass: 'idis-Page idis-Page--scheduledreport',


		/**
		 * 各列の定義
		 * @type {Object[]}
		 */
		columns: [
			{
				field: 'munic-detail', label: '詳細', sortable: false,
				renderCell: function (item) {
					if (!item.crtUserId && !item.reportedFlg) {
						return document.createTextNode('合計');
					}
					if (item.reportedFlg === '1'){
						return document.createTextNode('大阪府連携値');
					}
					if (item.municipalityCd !== PREF_MUNICIPALITY_CODE) {
						var gridNode = this.grid.domNode;
						var button = new Button({
							label: '詳細',
							onClick: function () {
								on.emit(gridNode, 'detailButtonClick', { item: item });
							}
						});
						return button.domNode;
					} else {
						return null;
					}

				}
			},
			helper.column('municipalityName', '区', { sortable: false }),
			{
				label: '人的被害', field: 'human', sortable: false,
				children: [
					helper.column('deadNumI', '死者', { sortable: false }),
					helper.column('unknownNumI', '不明者', { sortable: false }),
					helper.column('seriousNumI', '重傷者', { sortable: false }),
					helper.column('mildNumI', '軽傷者', { sortable: false }),
				]
			},
			{
				label: '住家被害', field: 'property', sortable: false,
				children: [
					helper.column('fullDestlHmI', '全壊', { sortable: false }),
					helper.column('halfDestlHmI', '半壊', { sortable: false }),
					helper.column('partDestlHmI', '一部損壊', { sortable: false }),
					helper.column('fldAbvHmI', '床上浸水', { sortable: false }),
					helper.column('fldBlwHmI', '床下浸水', { sortable: false })
				]
			},
			{
				label: '水道', field: 'water', sortable: false,
				children: [
					helper.column('watFailCurrI', '箇所', { sortable: false }),
				]
			},
			{
				label: '道路', field: 'traffic', sortable: false,
				children: [
					helper.column('rodDamageI', '箇所', { sortable: false }),
				]
			},
			{
				label: '橋梁', field: 'bridge', sortable: false,
				children: [
					helper.column('brgDamageI', '箇所', { sortable: false }),
				]
			},
			{
				label: '河川', field: 'river', sortable: false,
				children: [
					helper.column('rivrDamageI', '箇所', { sortable: false }),
				]
			},
			{
				label: '港湾', field: 'haver', sortable: false,
				children: [
					helper.column('hbrDamageI', '箇所', { sortable: false }),
				]
			}
		]
	});
});
