/**
 * <避難所状況詳細画面>
 * @module app/shelter/ShelterDetailPage.js
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dojo/date/locale',
    'dojo/dom-style',
    'dojo/text!./templates/ShelterDetailPage.html', // テンプレート文字列
    'dojo/json',
    'idis/view/dialog/InfoDialog',
    'idis/view/dialog/ConfirmDialog',
    'idis/control/Router',
    'idis/consts/STORAGE_KEY',
    'idis/model/UserInfo',
    'idis/store/IdisRest',
    'idis/service/Requester',
    'idis/control/Locator',
    'idis/consts/ACL',
    'idis/view/Loader',
    'idis/view/dialog/DialogChain',
    'idis/map/IdisMap',
    'idis/util/DateUtils',
    'dojo/on',
    'app/config',
    'leaflet',
    'idis/view/dialog/IdisDialog',
    'idis/util/storage/LocalStorage',
    'app/map/baselayer/BaseLayerPane',
    'app/model/DisasterInfo',
    'app/shelter/ShelterUtils',
    './_ShelterPageBase',
    // 以下、変数で受けないモジュール
    'dijit/layout/TabContainer',
    'dijit/layout/BorderContainer',
    'dijit/layout/AccordionContainer',
    'dijit/form/RadioButton',
    'dijit/form/Select',
    'dijit/form/Form',
    'dijit/form/ValidationTextBox',
    'dijit/form/Textarea',
    'dijit/TitlePane',
    'idis/consts/ACL',
    'idis/view/form/AclButton',
    'idis/view/form/DateTimeInput',
    'idis/view/form/Button',
    'idis/view/form/WordCountTextarea',
    'app/shelter/ShelterDeleteDialog',
    'app/provide/ProvideShelterRegisterDialog',
    'app/sample/view/SampleGrid',
    'app/shelter/ShelterTypeMultiSelector'
], function(module, declare, lang, locale, domStyle, template, json, InfoDialog, ConfirmDialog, Router, STORAGE_KEY,
        UserInfo, IdisRest, Requester, Locator, ACL, Loader, DialogChain, IdisMap, DateUtils, on, config,
        leaflet, IdisDialog, LocalStorage, BaseLayerPane, DisasterInfo, ShelterUtils, _ShelterPageBase) {
    /**
     * 避難所詳細画面
     * @class ShelterDetailPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _ShelterPageBase,
        /** @lends module:app/shelter/ShelterDetailPage~ShelterDetailPage# */
        {
            // テンプレート文字列
            templateString: template,
            store: null,
            innerShelter: null,

            /**
             * 災害ID
             */
            _disasterId: null,

            /**
             * 施設ID
             */
            _facilityId: null,

            /**
             * 避難所の市町村コード
             */
            _municipalityCd: null,

            /**
             * 避難所の市町村コード
             */
            _shelterMunicipalityCd: null,

            /**
             * 避難状況ID
             */
            _shelterId: null,

            /**
             * レイヤ名
             */
            _layerName: null,

            /**
             * レイヤ名
             */
            _lastStatus: null,

            /**
             * レイヤURL
             */
            _layerUrl: null,

            /**
             * 作成日時
             */
            _crtTimestamp: null,

            /**
             * 更新日時
             */
             _updTimestamp: null,

             /**
             * バージョン管理
             */
            _version: null,

            /**
             * 報告連番
             */
            _seqNum: null,

            /**
             * 緯度経度
             */
            _latitude: null,
            _longitude: null,

            /**
             * 施設に紐づく避難所情報の削除フラグ
             */
            _deleteAllFlg: false,

            /**
             * 開設済みフラグ
             */
            _isOpenFlg: true,

            /**
             * 混雑状況
             */
            _spaceStatus: null,

            /**
             * 本部確認状況
             */
            _confirmFlg: null,

            /**
             * 訂正フラグ （操作中の報が訂正によって登録されたものか否か）
             * true: 訂正、 false: 訂正以外
             */
            _correctFlg: null,

            /**
             * 自動承認モードフラグ
             * true: ON（自区）
             * false: OFF / ON（他区）
             */
            _autoConfirmFlg: null,

            /**
             * 閲覧モードフラグ
             * '0' (通常モード): false, '1' (閲覧モード): true
             */
            _viewMode: null,

            constructor: function() {
                // データ格納用オブジェクト
                this.store = new IdisRest({
                   idProperty: 'shelterId',
                   target: '/api/shelters'
                });
                // ダイアログ連鎖を登録
                this.chain = DialogChain.get(this);
                // 災害ID
                this._disasterId = DisasterInfo.getDisasterId();
                if(!this._disasterId) {
                   this._disasterId = 1;
                   console.warn('災害IDが設定されていません。');
                }
                this._shelterId = Locator.getQuery().shelterId;
                // シュミレーション機能
                this._scenarioId = Locator.getQuery().scenarioId;
                this._simulateFlg = Locator.getQuery().simulateFlg;
                this._eventId = Locator.getQuery().eventId;
                // 閲覧モード状況を取得
                this._viewMode = Locator.getQuery().viewMode === '1' ? true : false;
            },

            startup: function() {
                this.inherited(arguments);
                //取消対象選択ダイアログに変更イベントを追加
                var deletePage = this.innerDeleteDialog;
                deletePage.on('send', lang.hitch(this, function(value) {
                   this._deleteAllFlg = (value.deleteTarget === '1') ? false : true;
                   this.deleteShelterButtonClick();
                }));
                // タブ切替時、マップが正常表示されないため監視して再描画する
                var self = this;
                this.own(this.tabContainer.watch('selectedChildWidget', lang.hitch(this, function(name, oval, nval){
                    if (nval.id === 'mapContainerShelterDetail') {
                        self.map.remove();
                        self.initMap(this._latitude, this._longitude);
                        self.mapContainer.resize();
                    }
                })));
            },

            // DOMノードを生成するためのメソッド
            buildRendering: function() {
                this.inherited(arguments);
                this.initForm();
                this.own(this.shelterDeleteDialog);
            },

            /**
             * マップを初期化する。
             */
            initMap: function(lat, lng) {
                console.debug('FacilityDetailPage#initMap()');
                //中心アイコンを非表示にする
                LocalStorage.set(STORAGE_KEY.CENTER_MARK, '');
                // マップの生成
                var latlng = [lat, lng];
                this.map = new IdisMap(this.mapNode, {
                   config: config.map,
                   keyboard: false, // コメント時に+/-が使用できないため
                   touchExtend : false,
                   minZoom: 9,
                   maxZoom: 18,
                   drawControlTooltips: false}
                ).setView(latlng, 14);
                this.marker = leaflet.marker([lat, lng]).addTo(this.map);
                // destroy時にmapを破棄するよう設定
                this.own(this.map);
                this.own(on(this.map, 'click', lang.hitch(this, function(e) {
                   this.pointLat = e.latlng.lat;
                   this.pointLng = e.latlng.lng;
                   this.marker = leaflet.marker([lat, lng]).addTo(this.map);
                })));
            },

            /**
             * マーカーを追加する。
             */
            addMark: function(lat, lng) {
                this.removeMark();
                this.marker = leaflet.marker([lat, lng]).addTo(this.map);
            },

            /**
             * マーカーを削除する。
             */
            removeMark: function() {
                if(this.marker){
                    this.map.removeLayer(this.marker);
                }
            },

            /**
             * フォームを初期化する。
             */
            initForm: function() {
                if(this._simulateFlg === 'true'){
                   Requester.get('/data/simulation/' + this._eventId + '.json')
                   .then(lang.hitch(this, function(item) {
                       var data = item.shelterPostForm;
                       data.subData = item.shelterConversionForm;
                       this.initFormValue(data);
                   }), function(error) {
                       console.error('イベントの情報取得でエラー発生', error);
                   });
                } else {
                   Requester.get('/api/shelters/' + this._shelterId).then(lang.hitch(this, function(data) {
                       this.initFormValue(data);
                   }));
                }
            },

            /**
             * 詳細情報を設定する
             */
             initFormValue: function(item) {
                this.form.set('value', item);
                // 混雑状況
                this.setSpaceStatusButtonColor(item.spaceStatus);
                this._spaceStatus = item.spaceStatus;
                // 避難所名
                if(this._simulateFlg === 'true'){
                    this.facilityName.innerHTML = item.subData.facilityName;
                    this._facilityName = item.subData.facilityName;
                } else {
                    this.facilityName.innerHTML = item.facilityName;
                }
                // 避難所コード
                this.shelterCd.innerHTML = item.facilityId ? item.facilityId : '-';
                // 所在地
                this.address.innerHTML = item.address ? item.address : '-';
                // 最大収容人数
                this.capacity.innerHTML = item.capacity ? item.capacity : '-';
                // 避難所区分
                var _shelterType = '';
                if (item.designatedEvacShFlg==='1'){
                    _shelterType += '災害時避難所  ';
                }
                if (item.welfareEvacShFlg==='1'){
                    _shelterType += '福祉避難所  ';
                }
                if (item.temporaryEvacShFlg==='1'){
                    _shelterType += '臨時避難所  ';
                }
                if (_shelterType !==''){
                    this.shelterType.innerHTML = _shelterType || '&nbsp;';
                } else {
                    this.shelterType.innerHTML = '未設定';
                }
                // 前回調査日
                var reportDateTime = new Date(item.reportTime);
                this.preReportTime.innerHTML = DateUtils.format(reportDateTime, {time: 'HH:mm'});
                // 調査日
                this.reportTime.set('value', null);
                // チェックボックス項目をセット
                // 非公開フラグ 0:非公開, 1:公開
                if (item.privateFlg === '0') { this.privateFlg.set('checked', true); }
                // 配信済み情報がある場合、または第１報かつ本部確認済みの場合、
                // 非公開チェックボックスを変更不可にする
                if (item.lastShelter || (item.seqNum === 1 && item.confirmFlg === '1')) {
                    this.privateFlg.set('disabled', true);
                }
                // ライフライン（基本）
                if (item.elecDmgFlg === '1') { this.elecDmgFlg.set('checked', true); }
                if (item.gasDmgFlg === '1') { this.gasDmgFlg.set('checked', true); }
                if (item.waterDmgFlg === '1') { this.waterDmgFlg.set('checked', true); }
                if (item.sewageDmgFlg === '1') { this.sewageDmgFlg.set('checked', true); }
                if (item.phoneDmgFlg === '1') { this.phoneDmgFlg.set('checked', true); }
                if (item.docomoDmgFlg === '1') { this.docomoDmgFlg.set('checked', true); }
                if (item.softbankDmgFlg === '1') { this.softbankDmgFlg.set('checked', true); }
                if (item.auDmgFlg === '1') { this.auDmgFlg.set('checked', true); }
                if (item.rakutenDmgFlg === '1') { this.rakutenDmgFlg.set('checked', true); }
                // 詳細情報タブ
                if (item.noShoesShFlg === '1') { this.noShoesShFlg.set('checked', true); }
                if (item.noSmokingShFlg === '1') { this.noSmokingShFlg.set('checked', true); }
                //添付ファイル
                this.attachFileList = [];
                // プレビューを初期化
                this.clearPreview();
                this.displayTimeAreasByStatus(item.status);
                this._shelterMunicipalityCd = item.municipalityCd;
                this._shelterId = item.shelterId;
                this._facilityId = item.facilityId;
                this._municipalityCd = item.municipalityCd;
                this._version = item.version;
                this._layerName = item.layerName;
                this._layerUrl = item.layerUrl;
                this._lastShelter = item.lastShelter;
                this._initialStatus = item.status;
                this._seqNum = item.seqNum;
                this._confirmFlg = item.confirmFlg;
                this._correctFlg = item.correctFlg === '1' ? true : false;
                this._crtTimestamp = item.crtTimestamp;
                this._updTimestamp = item.updTimestamp;
                this._sendingId = item.sendingId;
                if(item.latitude && item.longitude) {
                    // 地図タブ切替で地図が初期化されるため、最新の緯度経度を保持しておく
                    this._latitude = item.latitude;
                    this._longitude = item.longitude;
                    // マップを初期化
                    this.initMap(item.latitude,item.longitude);
                } else {
                  this.initMap(config.map.latitude,config.map.longitude);
                }
                // 添付ファイルを設定
                if(item.attachFiles) {
                    for(var i=0; i<item.attachFiles.length; i++) {
                        this.attachFileList.push(item.attachFiles[i]);
                        this.showPreview(item.attachFiles[i], false);
                    }
                }
                this._privateFlg = item.privateFlg;
                this._activeFlg = item.activeFlg;
                this._isOpenFlg = item.status === '1';
                // item.municipalityCdを取得した後に
                // setButtonStatusを呼ぶ必要がある。
                // 自動承認モード状況を取得
                ShelterUtils.isAutoConfirmed(this._municipalityCd).then(lang.hitch(this, function(result){
                    // 自動承認モードフラグをセット
                    this._autoConfirmFlg = result;

                    // 直近参集者かチェック
                    item.recentattendeesFlg = false;
                    if (UserInfo.getMobilizationType() > 50 && UserInfo.getMobilizationType() < 75){
                        // 直近参集者情報を取得 
                        var self = this;            
                        Requester.get('/api/recentattendees/mobilization/' + UserInfo.getMobilizationType())
                        .then(lang.hitch(this, function (ramu) {
                            console.log('直近参集地域コード：' + ramu.municipalityCd);
                            console.log('直近参集地域名：' + ramu.municipalityName);
                            if (ramu.municipalityCd.toString() === item.municipalityCd){
                                item.recentattendeesFlg = true;
                            }
                            self.setButtonStatus(item);
                        }), function (error) {
                            self.chain.infoError(error);
                        });
                    } else {
                        // 照会結果を取得してから各ボタンをセット
                        this.setButtonStatus(item);                        
                    }   
                }));
                // Lアラート用施設情報を保持
                this._designatedEvacShFlg = item.designatedEvacShFlg;
                this._welfareEvacShFlg = item.welfareEvacShFlg;
                this._temporaryEvacShFlg = item.temporaryEvacShFlg;
                this._facilityName = item.facilityName;
                this._facilityNameKana = item.facilityNameKana;
                this._address = item.address;
                this._capacity = item.capacity;
                this._phoneNum = item.phoneNum;
                this._districtName = item.districtName;
             },

            /**
             * 更新・削除ボタンの活性/非活性を初期化する。
             */
            setButtonStatus: function(data){
                // 本部未確認かつ、初報である場合取消ボタンを表示
                // ※その他条件時に一致しない場合は非表示とする
                if (this._confirmFlg !== '0' || data.activeFlg !== '1' || this._seqNum !== 1){
                    domStyle.set(this.deleteButton.domNode, 'display', 'none');
                }

                // ユーザーの市町村情報をセット
                var _userMunicipalityCds = UserInfo.getMunicipalityCds()[0] !== '' ?
                    UserInfo.getMunicipalityCds() : UserInfo.getMunicipalityCd();
                // 避難所がユーザの管理対象市町でない場合は、各種ボタンを非表示
                if (_userMunicipalityCds.indexOf(data.municipalityCd) === -1 ||
                    ShelterUtils.isCityNormalUser(UserInfo)){
                    if (data.recentattendeesFlg === false){
                        domStyle.set(this.deleteButton.domNode, 'display', 'none');
                        domStyle.set(this.correctButton.domNode, 'display', 'none');
                        domStyle.set(this.updateButton.domNode, 'display', 'none');
                    }
                }
                // 最新報でない場合は「更新」「取消」ボタンを非表示にする
                if (data.activeFlg !== '1'){
                   domStyle.set(this.deleteButton.domNode, 'display', 'none');
                   domStyle.set(this.updateButton.domNode, 'display', 'none');
                }
                // 本部確認ボタンの活性状態をセット
                if (this._confirmFlg === '1') {
                    // 本部確認済みの場合、未確認ラベル非表示・ボタン非活性化
                    this.confirmButton.setDisabled(true);
                    domStyle.set(this.confirmFlgYet, 'display', 'none');
                    domStyle.set(this.confirmFlgDone, 'display', '');
                } else if (this._confirmFlg === '0' && this._activeFlg === '0') {
                    // 本部未確認報かつ過去報の場合、ボタン非活性化・注意ラベル表示
                    this.confirmButton.setDisabled(true);
                    domStyle.set(this.confirmCautionLabel, 'display', '');
                }

                // 自動承認モード解除中かつ本部確認可能ユーザの場合「本部確認」エリアを表示する
                if (!this._autoConfirmFlg &&
                    ShelterUtils.isHeadQuarterUser(UserInfo, this._municipalityCd)) {
                    domStyle.set(this.confirmArea, 'display', '');
                }

                // // 自動承認モード解除中の場合は本部確認可能ユーザ以外「取消」ボタンを非表示にする
                // if (!this._autoConfirmFlg &&
                //     !ShelterUtils.isHeadQuarterUser(UserInfo, this._municipalityCd)) {
                //     domStyle.set(this.deleteButton.domNode, 'display', 'none');
                // }

                // 災害シミュレーションの場合は「削除」「更新」ボタンを非表示にする
                if (this._simulateFlg === 'true') {
                   domStyle.set(this.deleteButton.domNode, 'display', 'none');
                   domStyle.set(this.correctButton.domNode, 'display', '');
                   domStyle.set(this.updateButton.domNode, 'display', 'none');
                }
                // 閲覧モードの場合、登録系ボタンを非表示
                if (this._viewMode) {
                    // 非表示対象：「更新」「訂正」「取消」「本部確認」
                    domStyle.set(this.deleteButton.domNode, 'display', 'none');
                    domStyle.set(this.correctButton.domNode, 'display', 'none');
                    domStyle.set(this.updateButton.domNode, 'display', 'none');
                    domStyle.set(this.confirmArea, 'display', 'none');
                }
            },

            /**
             * 避難所状況を訂正する。
             */
            onCorrectButtonClick: function() {
                console.debug('[訂正]ボタンがクリックされました。');
                this.onSubmit(false);
            },

            /**
             * 避難所状況（続報）を登録する。
             */
            onFollowUpButtonClick: function() {
              console.debug('[更新]ボタンがクリックされました。');
              this.onSubmit(true);
            },

            // 本部確認ボタンをクリックした時の挙動
            onChangeConfirmStatus: function() {
                console.debug('[本部確認]ボタンがクリックされました。');
                this._isConfirmClicked = true;
                // 訂正処理を呼び出す
                // 本部確認のみを変更して、同報での登録を行う目的
                this.onSubmit(false);
            },

            /**
             * フォーム入力内容をチェックする
             */
            validateForm: function(){
                var status = this.form.get('value').status;
                // 混雑状況の入力確認
                if (!this._spaceStatus) {
                    console.debug('混雑状況が選択されていません');
                    if (!this.infoDialog) {
                        this.infoDialog = new InfoDialog({
                            title: 'エラー',
                            content: '混雑状況が選択されていません'
                        });
                        this.infoDialog.show();
                        this.infoDialog = null;
                        return false;
                    }
                // 調査日の入力確認
                } else if(!this.reportTime.validate() || !this.reportTime._date.displayedValue ||
                       !this.reportTime._time.displayedValue) {
                   console.debug('調査日が選択されていません');
                   if (!this.infoDialog) {

                       this.infoDialog = new InfoDialog({
                           title : 'エラー',
                           content : '調査日が選択されていません'
                       });
                   }
                   this.infoDialog.show();
                   this.infoDialog = null;
                   return false;
                } else if(status !== '0' && (
                   !this.shelterStartTime.validate() || !this.shelterStartTime._date.displayedValue ||
                       !this.shelterStartTime._time.displayedValue)) {
                   console.debug('開設日時が入力されていません');
                   if (!this.infoDialog) {

                       this.infoDialog = new InfoDialog({
                           title : 'エラー',
                           content : '開設日時が入力されていません'
                       });
                   }
                   this.infoDialog.show();
                   this.infoDialog = null;
                   return false;
                //閉鎖日時が入力されている場合、その入力値が不正ならエラー
                } else if (status === '9' && (
                    !this.shelterEndTime.validate() || !this.shelterEndTime._date.displayedValue ||
                    !this.shelterEndTime._time.displayedValue)){
                    console.debug('閉鎖日時が入力されていません');
                    if (!this.infoDialog) {

                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '閉鎖日時が入力されていません'
                    });
                    }
                    this.infoDialog.show();
                    this.infoDialog = null;
                    return false;
                } else if((this.shelterEndTime._date.displayedValue || this.shelterEndTime._time.displayedValue) &&
                   !this.shelterEndTime.validate()) {
                   console.debug('閉鎖日時の入力値が不正です');
                   if (!this.infoDialog) {

                       this.infoDialog = new InfoDialog({
                           title : 'エラー',
                           content : '閉鎖日時の入力値が不正です'
                       });
                   }
                   this.infoDialog.show();
                   this.infoDialog = null;
                   return false;
                }
                //閉鎖時刻が開設日時より前に設定されている場合、エラー
                else if(status === '9' &&
                   (this.shelterEndTime._date.displayedValue && this.shelterEndTime._time.displayedValue &&
                   //閉鎖日が開設日より前の場合
                   (this.shelterEndTime._date.displayedValue < this.shelterStartTime._date.displayedValue ||
                   //または閉鎖日と開設日が同日かつ、時間が前の場合
                   (this.shelterEndTime._date.displayedValue === this.shelterStartTime._date.displayedValue &&
                   this.shelterEndTime._time.displayedValue < this.shelterStartTime._time.displayedValue)))){
                       console.debug('閉鎖日時が開設日時の前に設定されています');
                       if (!this.infoDialog) {
                           this.infoDialog = new InfoDialog({
                               title : 'エラー',
                               content : '閉鎖日時が開設日時の前に設定されています'
                           });
                       }
                       this.infoDialog.show();
                       this.infoDialog = null;
                       return false;
                }
                else if(!this.form.isValid()) {
                   console.debug('無効な値が入力されています');
                   if (!this.infoDialog) {
                       this.infoDialog = new InfoDialog({
                           title : 'エラー',
                           content : '無効な値が入力されています'
                       });
                   }
                   this.infoDialog.show();
                   this.infoDialog=null;
                   return false;
                }
                return true;
            },

            // 登録項目の値をセットする
            setRegisterValues: function(sendData) {
                // 本部確認状況
                if (ShelterUtils.isHeadQuarterUser(UserInfo, this._municipalityCd)) {
                    // 0: 本部未確認, 1: 完了
                    // 全体管理者・市本部ユーザ・区本部ユーザの場合、本部確認完了で登録する
                    sendData.confirmFlg = '1';
                } else if (this._autoConfirmFlg) {
                    // 自動承認モードが自区でONの場合、本部確認完了で登録する
                    sendData.confirmFlg = '1';
                } else {
                    sendData.confirmFlg = '0';
                }
                // 混雑状況
                sendData.spaceStatus = this._spaceStatus;
                // 非公開フラグ 0:非公開, 1:公開
                if (sendData.privateFlg) {
                    // 非公開フラグがdisabledでない場合
                    // チェックボックスの値をセット チェック有: ['on'], チェックなし: []
                    sendData.privateFlg.length > 0 ? sendData.privateFlg = '0' : sendData.privateFlg = '1';
                } else {
                    // 非公開フラグがdisabled場合（＝既に配信済み情報がある場合）
                    // フォームから値が取得できないため、ここで元の情報をセットする
                    sendData.privateFlg = this._privateFlg;
                }
                // ライフライン（基本）
                sendData.elecDmgFlg.length > 0 ? sendData.elecDmgFlg = '1' : sendData.elecDmgFlg = '0';
                sendData.gasDmgFlg.length > 0 ? sendData.gasDmgFlg = '1' : sendData.gasDmgFlg = '0';
                sendData.waterDmgFlg.length > 0 ? sendData.waterDmgFlg = '1' : sendData.waterDmgFlg = '0';
                sendData.sewageDmgFlg.length > 0 ? sendData.sewageDmgFlg = '1' : sendData.sewageDmgFlg = '0';
                sendData.phoneDmgFlg.length > 0 ? sendData.phoneDmgFlg = '1' : sendData.phoneDmgFlg = '0';
                sendData.docomoDmgFlg.length > 0 ? sendData.docomoDmgFlg = '1' : sendData.docomoDmgFlg = '0';
                sendData.softbankDmgFlg.length > 0 ? sendData.softbankDmgFlg = '1' : sendData.softbankDmgFlg = '0';
                sendData.auDmgFlg.length > 0 ? sendData.auDmgFlg = '1' : sendData.auDmgFlg = '0';
                sendData.rakutenDmgFlg.length > 0 ? sendData.rakutenDmgFlg = '1' : sendData.rakutenDmgFlg = '0';
                // 詳細情報タブ
                sendData.noShoesShFlg.length > 0 ? sendData.noShoesShFlg = '1' : sendData.noShoesShFlg = '0';
                sendData.noSmokingShFlg.length > 0 ? sendData.noSmokingShFlg = '1' : sendData.noSmokingShFlg = '0';
                // 閉鎖以外の場合、閉鎖時間を削除する
                // 一度閉鎖を選んで開設状況を変えると画面上で閉鎖時刻が見えなくなるため対策
                if(sendData.status !== '9'){
                    delete sendData.shelterEndTime;
                }
            },

            // 更新/訂正/配信ダイアログを表示する
            // 更新、訂正、混雑状況、本部確認ボタンから呼び出される
            onSubmit: function(followupFlg) {
                // ダイアログ連鎖を登録
                this.chain = DialogChain.get(this);
                if(this.validateForm()){
                   var sendData = this.form.get('value');
                   this.setRegisterValues(sendData);
                   //添付ファイルIDをセット
                   if(this.attachFileList.length > 0) {
                       var attachFileIds = [];
                       for(var i=0; i<this.attachFileList.length; i++) {
                           attachFileIds.push(this.attachFileList[i].shelterAtcFileId);
                       }
                       sendData.attachFileIds = attachFileIds.join(',');
                   } else {
                       sendData.attachFileIds = '';
                   }
                   delete sendData.shelterAtcFile;
                   sendData.facilityId = this._facilityId;
                   sendData.disasterId = this._disasterId;
                   // FIXME: shallow copyになっているためdeep copyにしたい
                   this._sendData = sendData;
                   if(followupFlg) {//更新の場合
                       if(this._activeFlg === '1'){
                            // 本部確認可能ユーザ以外または自動承認外の操作の場合、配信ダイアログを挟まない
                            if (ShelterUtils.isHeadQuarterUser(UserInfo, this._municipalityCd) || this._autoConfirmFlg) {
                                this.showShelterDialog(sendData, false, false);
                            } else {
                                this.followUpShelter(null);
                            }
                       } else {
                           this.followUpShelter(null);
                       }
                   } else { // 訂正の場合
                        // 本部確認可能ユーザによる訂正操作かつ、
                        // 操作対象報が本部確認未確認(='0')の場合はエラー
                        if (this._confirmFlg !== '1' && this._isConfirmClicked !== true
                                && ShelterUtils.isHeadQuarterUser(UserInfo, this._municipalityCd)) {
                            console.debug('本部ユーザによる本部未確認報の訂正を検知');
                            if (!this.infoDialog) {
                                this.infoDialog = new InfoDialog({
                                    title: 'エラー',
                                    content: '本部未確認の情報のため訂正できません。' +
                                        '<br>先に本部確認を完了してください。'
                                });
                                this.infoDialog.show();
                                this.infoDialog = null;
                                return false;
                            }
                        }
                        // 本部確認権限のないユーザによる訂正操作かつ、操作対象報が本部確認完了の場合エラー
                        if (this._confirmFlg === '1' && !ShelterUtils.isHeadQuarterUser(UserInfo, this._municipalityCd)) {
                            console.debug('非本部ユーザによる本部確認完了報の訂正を検知');
                            if (!this.infoDialog) {
                                this.infoDialog = new InfoDialog({
                                    title: 'エラー',
                                    content: '本部確認済みの情報のため、本部確認権限がないユーザでは訂正できません。'
                                });
                                this.infoDialog.show();
                                this.infoDialog = null;
                                return false;
                            }
                        }
                       if(this._activeFlg === '1'){
                            // 本部確認可能ユーザ以外または自動承認外の操作の場合、配信ダイアログを挟まない
                            if (ShelterUtils.isHeadQuarterUser(UserInfo, this._municipalityCd) || this._autoConfirmFlg) {
                                if (this._isConfirmClicked === true) {
                                    // 本部確認の場合
                                    // 第４引数に本部確認ボタンからの操作かどうかを持たせる
                                    this.showShelterDialog(sendData, true, false, true);
                                } else {
                                    // 本部確認ではなく、かつ本部確認ユーザによる訂正処理の場合
                                    this.showShelterDialog(sendData, true, false);
                                }
                            } else {
                                this.correctShelter(null);
                            }
                       } else {
                           this.correctShelter(null);
                       }
                   }
                }
            },

            followUpShelter: function(shelterInfo){
                var message = '';
                // 大阪府連携対象
                if(shelterInfo && this._simulateFlg !== 'true'){
                   message = '更新及び大阪府への配信を行います。よろしいですか？';
                // 大阪府連携非対象
                } else {
                   message = '更新します。よろしいですか？';
                }
                this.chain.confirm(message, lang.hitch(this, function(chain)  {
                   delete this._sendData.version;
                   delete this._sendData.layerName;
                   delete this._sendData.layerUrl;
                   delete this._sendData.shelterAtcFile;
                   if(shelterInfo) {
                       this._sendData.twitterFlg = shelterInfo.twitterFlg;
                       this._sendData.tweetList = shelterInfo.tweetList;
                   }
                   if(this._activeFlg === '1' && shelterInfo){
                       this._sendData.sendPostForm = shelterInfo.lalertForm;
                   }
                   var jsonStr = json.stringify(this._sendData);
                   var promise = Requester.post('/api/shelters',{
                       data: jsonStr
                    }).then(lang.hitch(this, function(data){
                       console.debug('避難所の更新に成功しました。data:' + data);
                       this._shelterId = data.shelterId;
                       var compMessage = shelterInfo && this._simulateFlg !== 'true' ? '更新・配信が完了しました。' : '更新が完了しました。';
                       chain.info(compMessage, '完了', lang.hitch(this, function(){
                           if(this._activeFlg === '1' && shelterInfo){
                               this.shelterRegisterDialog.getChildren()[0].closeDialog();
                           }
                           chain.hide();
                           Router.moveTo('shelter', {
                               municipalityCd: this._municipalityCd
                           });
                       }));
                   }), function(error){
                       // 失敗時
                       console.error(error);
                       chain.info('登録に失敗しました。', 'エラー');
                   });
                   Loader.wait(promise);
                }), lang.hitch(this, function(chain){ // キャンセル押下時
                    chain.hide();
                    if(this._activeFlg === '1' && shelterInfo){
                        this.shelterRegisterDialog.getChildren()[0].closeDialog();
                    }
                }));
            },

            correctShelter: function(shelterInfo){
                if(shelterInfo){
                   this._sendData.twitterFlg = shelterInfo.twitterFlg;
                   this._sendData.tweetList = shelterInfo.tweetList;
                   if(this._activeFlg === '1'){
                        this._sendData.sendPostForm = shelterInfo.lalertForm;
                    }
                }
                var sendData = this._sendData;
                sendData.shelterId = this._shelterId;
                sendData.version = this._version;
                sendData.layerName = this._layerName;
                sendData.layerUrl = this._layerUrl;
                var jsonStr = json.stringify(sendData);
                var message = '';
                // 本部確認
                if (this._isConfirmClicked) {
                    message = '本部確認を行います。よろしいですか？';
                }
                // 大阪府連携対象
                else if(shelterInfo && this._simulateFlg !== 'true'){
                   message = '訂正及び大阪府への配信を行います。よろしいですか？';
                // 大阪府連携非対象
                } else {
                   message = '訂正します。よろしいですか？';
                }
                // 災害シミュレーションから遷移してきた場合
                if(this._simulateFlg === 'true'){
                   switch (this.form.value.status) {
                       case '1':
                           this._statusName = '開設済み';
                           break;
                       case '2':
                           this._statusName = '受入不可';
                           break;
                       case '9':
                           this._statusName = '閉鎖';
                           break;
                       default:
                   }
                   var subSendData = {};
                   subSendData.statusName = this._statusName;
                   subSendData.facilityName = this._facilityName;
                   jsonStr = json.stringify({
                       shelterPostForm: this._sendData,
                       shelterConversionForm: subSendData,
                       scenarioId: this._scenarioId,
                       eventId: this._eventId});
                   this.chain.confirm(message, lang.hitch(this, function(chain){
                       var promise = Requester.put('/api/simulations/event/' + this._eventId,{
                           data: jsonStr,
                           headers: {'Content-Type': 'application/json; charset=utf-8'},
                           handleAs: 'json',
                           preventCache : true
                       }).then(lang.hitch(this, function(data){
                           console.debug('避難所イベントの訂正に成功しました。data:' + data);
                           this._shelterId = data.shelterId;
                           chain.info('避難所イベントの訂正が完了しました。', '完了', lang.hitch(this, function(){
                               chain.hide();
                               if(this._activeFlg === '1' && shelterInfo){
                                  this.shelterRegisterDialog.getChildren()[0].closeDialog();
                               }
                               Router.moveTo('simulation/event', {scenarioId: this._scenarioId});
                           }));
                       }), function(error){
                           // 失敗時
                           console.error(error);
                           chain.infoError(error);
                       });
                       Loader.wait(promise);
                   }));
                } else {
                    // 通常時
                   this.chain.confirm(message, lang.hitch(this, function(chain){
                       var promise = Requester.put('/api/shelters/' + this._shelterId, {
                           data: jsonStr
                        }).then(lang.hitch(this, function(data){
                           console.debug('避難所の訂正に成功しました。data:' + data);
                           this._shelterId = data.shelterId;
                           var compMessage = shelterInfo && this._simulateFlg !== 'true' ?
                                            '訂正・配信が完了しました。' : '訂正が完了しました。';
                           if (this._isConfirmClicked) {
                               compMessage = '本部確認が完了しました。';
                           }
                           chain.info(compMessage, '完了', lang.hitch(this, function(){
                               chain.hide();
                               if(this._activeFlg === '1' && shelterInfo){
                                  this.shelterRegisterDialog.getChildren()[0].closeDialog();
                               }
                               Router.moveTo('shelter', {
                                  municipalityCd: this._municipalityCd
                               });
                           }));
                       }), function(error){
                           // 失敗時
                           console.error(error);
                           var errMessage = this._isConfirmClicked ?
                                        '本部確認に失敗しました。' : '訂正に失敗しました。';
                           chain.info(errMessage, 'エラー');
                       });
                       Loader.wait(promise);
                    }), lang.hitch(this, function(chain){ // キャンセル押下時
                        chain.hide();
                        if(this._activeFlg === '1' && shelterInfo){
                            this.shelterRegisterDialog.getChildren()[0].closeDialog();
                         }
                        // 本部確認経由フラグを初期化
                        if (this._isConfirmClicked) {
                            this._isConfirmClicked = false;
                        }
                    }));
                }
            },

            showDeleteDialog: function() {
                // 削除対象を選択できるのは、最新報かつ第2報以上のときとする
                if(this._activeFlg === '1' && this._seqNum > 1){
                   this.shelterDeleteDialog.show();
                }else{
                   this.deleteShelterButtonClick();
                }

            },

            /**
             * 配信情報(開設避難所情報)ダイアログを表示する。
             */
            showShelterDialog: function(obj, isCorrect, isCancel, isConfirm) {
                // Requester.get('/api/facility/' + obj.facilityId + '/' + obj.disasterId
                // ).then(lang.hitch(this,function(item) {
                   // 非公開指定の報が更新/訂正/取消されていた場合、配信ダイアログを挟まずに避難所を登録する
                   if(!obj.privateFlg || obj.privateFlg !== '1'){
                       if(!isCorrect && !isCancel){
                           return this.followUpShelter(null);
                       } else if (isCorrect){
                           return this.correctShelter(null);
                       } else if(isCancel){
                           return this.deleteShelter(null);
                       }
                   }
                   if (isCancel && !this._deleteAllFlg && this._lastShelter){
                       // 配信済みの前報があり、「最新報のみを取消」を選んだ場合、
                       // Lアラートに連携するのは配信済みの前報の内容なので、
                       // objを前報で置き換える
                       obj =  this._lastShelter;
                   }
                   if (isCancel && this._deleteAllFlg && this._confirmFlg === '0' && this._lastShelter){
                        // 配信済みの前報があり、「過去報を含め取消」を選んだ場合、
                        // かつ操作中の報が本部未確認報である場合、
                        // Lアラートに連携するのは配信済みの前報の内容なので、
                        // objを前報で置き換える
                        obj =  this._lastShelter;
                    }
                   // Lアラート項目の整形
                   // 日付の形式を、Lアラート用にフォーマットする
                   var shelterStartTime = this.formatDate(obj.shelterStartTime);
                   var shelterEndTime = obj.shelterEndTime ? this.formatDate(obj.shelterEndTime) : null;
                   var shelterStartOrEndTime = shelterStartTime;
                   // Lアラート 開設状況
                   var sort = '開設';
                   if (obj.shelterEndTime && obj.shelterEndTime !== null) {
                       sort = '閉鎖';
                       shelterStartOrEndTime = shelterEndTime;
                   }
                   // Lアラート 避難者数・避難世帯数
                   var evaqueeNum4Lalert = isNaN(obj.evaqueeNum) ? 0 : Number(obj.evaqueeNum);
                   var evacHouseholdNum4Lalert = isNaN(obj.evacHouseholdNum) ? 0 : Number(obj.evacHouseholdNum);
                   // 外部公開情報をセットする（0: 公開, 1: 非公開)
                   var publish4Lalert = obj.privateFlg === '1' ? '0' : '1';
                   // 避難所種別・避難所種別で表現しきれない情報をセットする
                   var evacShelterType = '避難所';
                   var evacShelterTypeDetail = '';
                   if (this._welfareEvacShFlg === '1') {
                       // 福祉避難所の場合、避難所種別で表現しきれない情報を「福祉避難所」にする
                       evacShelterTypeDetail = '福祉避難所';
                   }
                   if (this._temporaryEvacShFlg === '1') {
                       // 臨時避難所の場合、避難所種別を「臨時避難所」にする
                       // フラグが複数の場合も、臨時避難所が選択されていれば臨時避難所とみなす
                       evacShelterType = '臨時避難所';
                   }
                    // 指定区分をセットする
                    var evacSuitableShelter = '指定なし';
                    if(this._designatedEvacShFlg === '1') {
                        // 災害時避難所の場合、指定区分を「指定避難所」にする
                        evacSuitableShelter = '指定避難所';
                    }

                   var evaqueeData = [{
                       facilityId: obj.facilityId,
                       evacShelterName: this._facilityName,
                       evacShelterNameKana: this._facilityNameKana,
                       evacShelterType: evacShelterType,
                       evacShelterTypeDetail: evacShelterTypeDetail,
                       evacSuitableShelter: evacSuitableShelter,
                       evacShelterSort: sort,
                       evacShelterLatitude: this._latitude,
                       evacShelterLongitude: this._longitude,
                       address: this._address,
                       evaqueeNum: evaqueeNum4Lalert ? evaqueeNum4Lalert : 0,
                       evacHouseholdNum: evacHouseholdNum4Lalert ? evacHouseholdNum4Lalert : 0,
                       evacShelterDatetime: shelterStartOrEndTime,
                       evacTopical: obj.comment ? obj.comment : '',
                       evacShelterStatus: obj.spaceStatus,
                       publish: publish4Lalert,
                       capacity: this._capacity,
                       contactInfo: this._phoneNum,
                       district: this._districtName
                   }];
                   var isRemoving = false;
                   if ( isCancel && ( !this._lastShelter || this._deleteAllFlg )){
                       // 取り消しかつ配信済みの前報がない
                       // または取消かつ全報を取り消す場合
                       isRemoving = true;
                   }
                   var args = {};
                   //  Lアラート更新種別をセット。
                   // ひとまずは「更新/訂正(=02)」を入れておくが、
                   // isCancel=trueの場合は、条件に応じてあとでinitDialog内で内容が変更される。
                   var distributionType = '02';

                    // 大阪市対応：本部確認の場合は、ここで本来意図していた更新種別に入れ替える
                    // この後initDialog内で、再開設の場合のみ「更新・訂正(=02)」に振り直される
                    // 新規登録報の本部確認の場合、「新規(=01)」をセット かつ isCorrect : false
                    // 更新報の本部確認の場合、「更新・訂正(=02)」をセット かつ isCorrect : false
                    // 訂正報の本部確認の場合、「更新・訂正(=02)」をセット かつ isCorrect : true
                    // 取消の本部確認は存在しないため不問
                    // 配信ダイアログの後の分岐の為、isCorrectの中身を保管
                    var isCorrectOriginal = isCorrect;
                    if (isConfirm) {
                        if (this._seqNum === 1 && !this._correctFlg) {
                            // 新規登録：第１報かつ訂正フラグfalse
                            console.debug('[本部確認] 新規登録報の配信を行います');
                            distributionType = '01';
                            isCorrect = false;
                        } else if (this._seqNum > 1 && !this._correctFlg) {
                            // 更新：第２報以上かつ訂正フラグfalse
                            console.debug('[本部確認] 更新報の配信を行います');
                            distributionType = '02';
                            isCorrect = false;
                        } else if (this._correctFlg) {
                            if (this._sendingId) {
                                // 訂正対象が配信済みの場合
                                // 訂正：訂正フラグtrue
                                console.debug('[本部確認] 訂正報の配信を行います');
                                distributionType = '02';
                                isCorrect = true;
                            } else {
                                // 訂正対象が未配信の場合（未確認ユーザの更新→未確認ユーザの訂正の場合）
                                // 更新：訂正フラグfalse
                                console.debug('[本部確認] 更新報の配信を行います');
                                distributionType = '02';
                                isCorrect = false;
                            }
                        }
                    }

                   args.shelterArgs = {
                       sendCategory : '02',
                       evacTimestamp: new Date(),
                       evaqueeData: evaqueeData,
                       // 更新種別 '01':新規, '02':更新・訂正, '03':取消
                       distributionType: distributionType,
                       seqNum: this._seqNum
                   };
                   args.facilityId = obj.facilityId;
                   args.evacOrderArgs = null;

                   // ダイアログの最初の子要素が登録画面
                   var dialog = this.shelterRegisterDialog.getChildren()[0];
                   // ダイアログの画面を初期化する。
                   dialog.initDialog(args, isCorrect, isCancel, isRemoving);
                   // ダイアログのsendイベントを受け取れるようにする
                   dialog.on('send', lang.hitch(this, function(evt) {
                       if(!isCorrectOriginal && !isCancel){
                           return this.followUpShelter(evt.value);
                       } else if (isCorrectOriginal){
                           return this.correctShelter(evt.value);
                       } else if(isCancel){
                           return this.deleteShelter(evt.value);
                       }
                   }));
                   // ダイアログのcancelイベントを受け取れるようにする
                   dialog.on('cancel', lang.hitch(this, function() {
                        // 本部確認経由フラグを初期化
                        if (this._isConfirmClicked) {
                            this._isConfirmClicked = false;
                        }
                   }));
                   // ダイアログを破棄できないように設定する。
                   this.shelterRegisterDialog.set('closable', false);
                   // ダイアログを表示する。
                   this.shelterRegisterDialog.show();
                // }));
            },

            deleteShelterButtonClick: function() {
                if(this.validateForm()){
                   Requester.get('/api/shelters/' + this._shelterId).then(lang.hitch(this, function(data) {
                   console.debug('[取消]ボタンがクリックされました。');
                      var sendData;
                      sendData = this.form.get('value');
                      this.setRegisterValues(sendData);
                      if (data.shelterStartTime !== null) {
                           sendData.shelterStartTime = new Date(data.shelterStartTime);
                       }
                       if (data.shelterEndTime !== null) {
                           sendData.shelterEndTime = new Date(data.shelterEndTime);
                       }
                       this._sendData = sendData;
                       delete sendData.shelterAtcFile;
                       sendData.facilityId = this._facilityId;
                       sendData.disasterId = this._disasterId;
                       if (this._deleteAllFlg && this._lastShelter ||
                          (!this._deleteAllFlg && this._confirmFlg === '1')) {
                           // 過去報含め取消かつ前報が連携済み または 最新報のみ取消かつ本部確認済みの場合
                           this.shelterDeleteDialog.hide();
                           this.showShelterDialog(sendData, false, true);
                       } else {
                            this.deleteShelter(null);
                       }
                   }));
                }
            },

            deleteShelter: function(shelterInfo){
                var message = '';
                // 大阪府連携対象
                if(shelterInfo){
	                   message = '取消及び大阪府への配信を行います。よろしいですか？';
                       this._sendData.twitterFlg = shelterInfo.twitterFlg;
                       this._sendData.tweetList = shelterInfo.tweetList;
                // 大阪府連携非対象
                } else {
	                   message = '取消します。よろしいですか？';
                }
                var sendData = this._sendData;
                if(this._activeFlg === '1' && shelterInfo){
                   sendData.sendPostForm = shelterInfo.lalertForm;
                }
                sendData.facilityId = this._facilityId;
                var jsonStr = json.stringify(sendData);
                var delUrl = (this._deleteAllFlg === true) ? '/api/shelters/delAll/' : '/api/shelters/del/';
                this.chain.confirm(message, function(chain) {
                   var promise = Requester.put(delUrl + this._shelterId,{
                       data: jsonStr,
                       headers: {'Content-Type': 'application/json; charset=utf-8'},
                       handleAs: 'json',
                       preventCache : true
                   }).then(lang.hitch(this, function(data) {
                   	console.debug('避難所の取り消しに成功しました。data:' + data);
                       var compMessage = shelterInfo && this._simulateFlg !== 'true' ? '取消・配信が完了しました。' : '取消が完了しました。';
                       chain.info(compMessage, '完了', lang.hitch(this, function(){
                           if(this._activeFlg === '1' && shelterInfo){
                               this.shelterRegisterDialog.getChildren()[0].closeDialog();
                           }
                           chain.hide();
                           Router.moveTo('shelter', {
                               municipalityCd: this._municipalityCd
                           });
                       }));
                   }), function(err) {
                       // 失敗時
                       if(err.response.data && typeof(err.response.data) === 'string'){
                           err.response.data = json.parse(err.response.data);
                       }
                       chain.info('取消に失敗しました。', 'エラー');
                   });
                   Loader.wait(promise);
                }, lang.hitch(this, function(chain){ // キャンセル押下時
                    chain.hide();
                    if(this._activeFlg === '1' && shelterInfo){
                        this.shelterRegisterDialog.getChildren()[0].closeDialog();
                    }
                }));
            },

        //パンくずリストのボタンを押下したときの挙動
        onShelterAdminPageLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('shelter/admin', {
                viewMode: Locator.getQuery().viewMode
            });
        },

        onShelterListPageLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('shelter', {
                municipalityCd : this._municipalityCd,
                viewMode: Locator.getQuery().viewMode
            });
        },

        // 混雑状況をクリックした時の挙動
        onStatusButtonClick: function(evt) {
            // ユーザーの市町村情報をセット
            var _userMunicipalityCds = UserInfo.getMunicipalityCds()[0] !== '' ?
                UserInfo.getMunicipalityCds() : UserInfo.getMunicipalityCd();
            var target = evt.target;
            // 比較のため、前の選択値を保持
            var lastStatus = this._spaceStatus;
            // 押下したボタンに応じて、値を保持し色を変える
            this._spaceStatus = target.value;
            this.setSpaceStatusButtonColor(this._spaceStatus);
            // 登録確認ダイアログを表示する（登録権限のあるユーザかつ所属区の避難所のみ（閲覧モードを除く））
            if (UserInfo.hasWriteAuthz('F05006') && !this._viewMode &&
                !ShelterUtils.isCityNormalUser(UserInfo) &&
                _userMunicipalityCds.indexOf(this._municipalityCd) !== -1) {
                var message = '';
                if (lastStatus !== this._spaceStatus) {
                    message = '混雑状況が変更されました。このまま更新を行いますか？';
                } else {
                    message = 'このまま更新を行いますか？';
                }
                this.chain.confirm(message, lang.hitch(this, function() {
                    this.chain.hide();
                    // 更新扱いで登録を行う
                    this.onSubmit(true);
                }));
            }
        },

        // 混雑状況のボタン色をセットする
        setSpaceStatusButtonColor: function(value) {
            // '0': 空き, '1': 混雑, '2': 満員
            if (value === '0') {
                this.spaceStatusQuiet.set('class', 'wide-button accent');
                this.spaceStatusBusy.set('class', 'wide-button');
                this.spaceStatusFull.set('class', 'wide-button');
            } else if (value === '1') {
                this.spaceStatusQuiet.set('class', 'wide-button');
                this.spaceStatusBusy.set('class', 'wide-button accent');
                this.spaceStatusFull.set('class', 'wide-button');
            } else {
                this.spaceStatusQuiet.set('class', 'wide-button');
                this.spaceStatusBusy.set('class', 'wide-button');
                this.spaceStatusFull.set('class', 'wide-button accent');
            }
        },

        // 地図タブをクリックした時の挙動
        onMapTabClick: function() {
            console.log('clicked on MapTab');
            // タブ切替時、マップが正常表示されないためリサイズする
            this.map.remove();
            this.initMap();
            this.container.resize();
        },

        // キャンセルボタンを押下したら前の画面に戻る
        cancelButtonClick: function() {
            console.debug('[キャンセル]ボタンがクリックされました。');
            this.leave();
        },

        /**
         * 背景地図ダイアログを表示する。
         */
        showBaseLayerDialog: function() {
            if (!this._baseLayerDialog) {
                // 初回呼び出し時にインスタンス生成
                this._baseLayerDialog = new IdisDialog({
                   noUnderlay: true,
                   title: '背景地図',
                   content: new BaseLayerPane({map: this.map})
                });
                // 画面が破棄された際に連れて行く
                this.own(this._baseLayerDialog);
            }
            this._baseLayerDialog.show();
        }
    });
});
