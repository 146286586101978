define([
    'module',
    'dojo/_base/declare',
    'dojo/date/locale',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',

], function (module, declare, locale, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--scheduledreport',

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [

            helper.buttonColumn('detail', '詳細'),
            helper.column('municipalityName', '区', {sortable: false}),
            helper.column('admNum', '管理番号', { sortable: true }),
            helper.column('seqNum', '連番', { sortable: false }),
            helper.column('damageTypeNameList', '被害の種類', { sortable: false }),

            helper.column('reportCrtTimestamp', '発生日時', {sortable: true,
                formatter: function (val) {
                    if (val) {
                        var date = locale.parse(val, {
                            datePattern: 'yyyy-MM-dd',
                            timePattern: 'HH:mm:ss'
                        });
                        return locale.format(date, {
                            datePattern: 'yyyy-MM-dd',
                            timePattern: 'HH:mm'
                        });
                    } else {
                        return '';
                    }
                }
            }),
            {
                label: '人的被害', field: 'human', sortable: false,
                children: [
                    helper.column('deadNumI', '死者', { sortable: false }),
                    helper.column('unknownNumI', '不明者', { sortable: false }),
                    helper.column('seriousNumI', '重傷者', { sortable: false }),
                    helper.column('mildNumI', '軽傷者', { sortable: false }),
                ]
            },
            {
                label: '住家被害', field: 'property', sortable: false,
                children: [
                    helper.column('fullDestlHmI', '全壊', { sortable: false }),
                    helper.column('halfDestlHmI', '半壊', { sortable: false }),
                    helper.column('partDestlHmI', '一部損壊', { sortable: false }),
                    helper.column('fldAbvHmI', '床上浸水', { sortable: false }),
                    helper.column('fldBlwHmI', '床下浸水', { sortable: false })
                ]
            },
            {
                label: '水道', field: 'water', sortable: false,
                children: [
                    helper.column('watFailCurrI', '箇所', { sortable: false }),
                ]
            },
            {
                label: '道路', field: 'traffic', sortable: false,
                children: [
                    helper.column('rodDamageI', '箇所', { sortable: false }),
                ]
            },
            {
                label: '橋梁', field: 'bridge', sortable: false,
                children: [
                    helper.column('brgDamageI', '箇所', { sortable: false }),
                ]
            },
            {
                label: '河川', field: 'river', sortable: false,
                children: [
                    helper.column('rivrDamageI', '箇所', { sortable: false }),
                ]
            },
            {
                label: '港湾', field: 'haver', sortable: false,
                children: [
                    helper.column('hbrDamageI', '箇所', { sortable: false }),
                ]
            }
        ]
    });
});
