/**
 * 利用者更新用ダイアログモジュール。
 * @module app/user/UserDetailDialog
 */
define([
    'module',
    'leaflet',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/text!./templates/UserDetailDialog.html',
    './_UserDialogBase',
    './mobilizationType',
    'idis/consts/ACL',
    'idis/model/UserInfo',
    'idis/view/dialog/InfoDialog',
    'idis/view/Loader',
    'idis/service/Requester',
    'app/config',
    'idis/service/GeoService',
    // 以下、変数で受けないモジュール
    'app/view/form/MunicipalitySelector',
    // 'app/view/form/RegionSelector',
    'app/view/form/DeptSelector',
    'app/view/form/UserOrganizationSelector',
    'app/view/form/OfficialPositionSelector',
    './UserMailDialog',
    'idis/view/form/RadioGroup',
    'idis/view/form/WordCountTextarea',
    'idis/view/form/Button'
], function (module, leaflet, declare, lang, domStyle, template, _UserDialogBase, mobilization, ACL,
    UserInfo, InfoDialog, Loader, Requester, config, GeoService) {

    return declare(module.id.replace(/\//g, '.'), _UserDialogBase,
        {
            // テンプレート文字列
            templateString: template,

            /**
             * 緯度経度・座標変換用
             * @type {Object}
             */
            _geoService: null,

            // 更新前の画面項目値の格納用
            _organization: null, // 平常時所属部課
            _officialPosition: null, // 役職
            _officialPositionName: null, // 役職名
            _supportStation: null, // 応援先所属
            _emergencyOrganization: null,// 緊急時参集所属
            _emergencyOrganizationName: null,// 緊急時参集所属

            _mobilizationSection: null, // 動員区分
            _mobilizationType: null, // 動員種別
            _account: null, // アカウント有効無効
            _userName: null, // ユーザ名(漢字)
            _userNameKana: null, // ユーザ名(カナ)
            _officialMailAddress: null, // 庁内メールアドレス
            _personalMailAddress: null, // 個人用メールアドレス
            _note: null, // 備考

            _exchangeCode: null, // iOSアプリ引き換えコード
            _issued: null, // 発行済or未発行
            _userPassword: null,

            constructor: function () {
            },

            /**
             * buildRendering
             */
            buildRendering: function () {
                this.inherited(arguments);
                this.own(this.innerUserMailDialog);
            },

            /**
             * ロールコード
             */
            _roleCd: null,

            /**
             * ロール変更可否フラグ
             */
            _isRoleCdFixed: null,

            // バージョン
            _version: null,

            /**
             * フォームを初期化する。
             */
            initForm: function (object) {
                this._userId = object.userId;
                this._issued = object.exchangeCode ? '発行済' : '未発行';
                this._userName = object.userName;
                // this._userPassword = object.userPassword; // ハッシュ値なので使用しない
                var self = this;
                var page = this.form;
                this.initSelectBox().then(lang.hitch(this, function () {
                    return Requester.get('/api/users/' + this._userId);
                })).then(function (data) {
                    var item = data;
                    delete item.userPassword;
                    // ログインユーザが全体管理者・一般職員・応援職員・自主防災・福祉避難所・施設管理者・下水処理事業者・
                    // 道路管理事業者ユーザ以外の場合、個人用メールアドレスをマスク
                    if (item.personalMailAddress) {
                        if (!(UserInfo.getRoleCd() === ACL.ADMIN_USER || UserInfo.getRoleCd() === ACL.SYSMGR_USER ||
                            UserInfo.getRoleCd() === ACL.IPPAN_USER || self._acceptableUser.includes(UserInfo.getRoleCd()))) {
                            item.personalMailAddress = '***';
                        }
                    }
                    page.set('value', item);
                    self.roleCdSelect.set('value', item.roleCd);
                    self._roleCd = item.roleCd;
                    self._version = item.version;
                    self._exchangeCode = item.exchangeCode;
                });
                var role = UserInfo.getRoleCd();
                // var value = this.form.get('value');
                // var org = value.organizationSelector;
                // self.organizationSelector.set('value', org);
                if (role !== ACL.ADMIN_USER && UserInfo.getOrganization().unitCd !== null) {
                    domStyle.set(self.organizationSelector.selectButton.domNode, 'display', 'none');
                }
                // 動員種別にモデルをセット
                this.mobilizationType.set('store', mobilization);
                // 動員区分をクリア
                this.mobilizationSection.set('value', '');

                // 更新前の画面項目値を確保
                this._roleCd = object.roleCd;
                this._organization = object.organization;
                this._officialPosition = object.officialPosition;
                this._officialPositionName = object.officialPositionName;
                this._supportStation = object.supportStation;
                this._emergencyOrganization = object.emergencyOrganization;
                this._emergencyOrganizationName = object.emergencyOrganizationName;

                this._mobilizationSection = object.mobilizationSection;
                this._mobilizationType = object.mobilizationType;
                this._account = object.account;
                this._userName = object.userName;
                this._userNameKana = object.userNameKana;
                this._officialMailAddress = object.officialMailAddress;
                this._personalMailAddress = object.personalMailAddress;
                this._note = object.note;
            },

            /**
             * 利用者を更新する。
             */
            onSubmit: function () {
                try {
                    console.debug('[更新]ボタンがクリックされました。');

                    // フォームのバリデーションを実施
                    if (!this.form.validate()) {
                        return false;
                    }

                    // 固有のバリデーションを実施
                    if (!this.validate()) {
                        return false;
                    }

                    var value = this.form.get('value');
                    delete value.station;

                    // 緊急時参集所属
                    if (value.roleCd.substr(0, 3) === 'R03' && value.roleCd !== ACL.IPPAN_USER && !(this._acceptableUser.includes(UserInfo.getRoleCd()))) {
                        // 非市職員の場合、選択値は無効
                        value.emergencyOrganization = '';
                    } else {
                        // 市職員の場合、入力値は無効
                        value.emergencyOrganizationName = '';
                        // 市職員の場合、組織の接頭辞を付与
                        if (value.emergencyOrganization &&
                            (value.emergencyOrganization.length === 6 || value.emergencyOrganization.length === 9)) {
                            var prf = value.emergencyOrganization.substr(0, 1); // 組織の接頭辞
                            value.emergencyOrganization = prf + value.emergencyOrganization;
                        }
                    }

                    value.userId = this._userId;

                    var role = UserInfo.getRoleCd();
                    // ログインユーザが市本部管理者・所属本部管理者の場合、非活性にした画面項目の値を再設定
                    if (role === ACL.SHMGR_USER || role === ACL.XXMGR_USER) {
                        value.officialMailAddress = this._officialMailAddress;
                        value.personalMailAddress = this._personalMailAddress;
                        // ログインユーザが一般職員・応援職員・自主防災・福祉避難所・施設管理者・下水処理事業者・
                        // 道路管理事業者の場合、非活性にした画面項目の値を再設定
                    } else if (role === ACL.IPPAN_USER || this._acceptableUser.includes(role) ){
                        value.mobilizationSection = this._mobilizationSection;
                        value.mobilizationType = this._mobilizationType;
                        value.account = this._account;
                        value.userName = this._userName;
                        value.userNameKana = this._userNameKana;
                        value.officialMailAddress = this._officialMailAddress;
                        value.note = this._note;
                    }

                    value.officialMailAddress =
                        value.officialMailAddress ? value.officialMailAddress.trim() : value.officialMailAddress;
                    value.personalMailAddress =
                        value.personalMailAddress ? value.personalMailAddress.trim() : value.personalMailAddress;

                    // メールアドレス確認要否フラグ（要:1, 否:0）
                    value.isMailAddChk = (!value.officialMailAddress && !value.personalMailAddress) ? '0' : '1';
                    // 両方未設定の場合、確認不要。いずれか、または両方のメールアドレスが設定済の場合、確認要。
                    if (value.isMailAddChk === '1') {
                        // 設定済のアドレスが更新か否か（入力アドレスと既存アドレスとの比較：それぞれに同じであれば確認不要）
                        if ((value.officialMailAddress === this._officialMailAddress) &&
                            (value.personalMailAddress === this._personalMailAddress)) {
                            value.isMailAddChk = '0';
                            // 差異があっても、null vs "" の場合は確認不要
                        } else {
                            if ((!value.officialMailAddress && !this._officialMailAddress) &&
                                (value.personalMailAddress === this._personalMailAddress)) {
                                value.isMailAddChk = '0';
                            } else if ((value.officialMailAddress === this._officialMailAddress) &&
                                (!value.personalMailAddress && !this._personalMailAddress)) {
                                value.isMailAddChk = '0';
                            }
                        }
                    }
                    // メールアドレス確認要否によりダイアログに表示する更新後メッセージを編集
                    var message = '利用者情報を更新しました。<br>';
                    if (value.isMailAddChk === '1') {
                        var oMailAddress = !value.officialMailAddress ? '（未設定）' : value.officialMailAddress;
                        var pMailAddress = !value.personalMailAddress ? '（未設定）' : value.personalMailAddress;
                        // 個人用メールアドレスがマスクされている場合はダイアログ上でもマスクする
                        if (pMailAddress) {
                            pMailAddress = this.personalMailAddress.get('value') === '***' ? '***（非表示）' : pMailAddress;
                        }
                        message += '<br>メールアドレスが登録または更新されました。以下の登録アドレスに確認メールを送信しますか？<br>';
                        message += '&nbsp;庁内アドレス：' + oMailAddress + '<br>';
                        message += '&nbsp;個人アドレス：' + pMailAddress + '<br><br>';
                    }
                    value.message = message;

                    // 動員情報変更有無フラグ（有:1, 無:0）※いずれかが更新されている場合'1'
                    var isMobileChange = (value.mobilizationSection === this._mobilizationSection) &&
                        ((value.mobilizationType === this._mobilizationType) ||
                            // 動員種別が未設定の場合、画面値：''、 サーバ値：nullとなるので、この場合も変更なしとみなす
                            (!value.mobilizationType && !this._mobilizationType)) ? 0 : 1;

                    // アカウントフラグ（有効:0, 無効:1）※更新されている場合'1'
                    var isAccountChange = (value.account === this._account) ? 0 : 1;

                    // 役職変更有無フラグフラグ
                    // 役職コードが課長級以上（3：課長級、2：部長級、1：局長級、0：特別・区長）
                    var isOldMgrPosition = false; // 更新前
                    var isNewMgrPosition = false; // 更新後
                    switch (this._officialPosition) {
                        case '0': case '1': case '2': case '3':
                            isOldMgrPosition = true;
                            break;
                        default: break;
                    }
                    switch (value.officialPosition) {
                        case '0': case '1': case '2': case '3':
                            isOldMgrPosition = true;
                            break;
                        default: break;
                    }
                    var isMgrPositionChange = (isOldMgrPosition === isNewMgrPosition) ? 0 : 1;

                    // 所属局変更有無フラグフラグ
                    var isOrgChange = (value.organization === this._organization) ? 0 : 1;

                    // 変更有無フラグ（有:'1', 無:'0'）※動員・チャットグループ用
                    value.isChange = '0';
                    if (isMobileChange + isAccountChange + isMgrPositionChange + isOrgChange > 0) {
                        // いずれかが変更ありの場合フラグオン
                        value.isChange = '1';
                    }

                    // if (!this.address.value || !this.latitude.value || !this.longitude.value) {
                    //     var self = this;
                    //     this.fillAddLatLng().then(function () {
                    //         value.latitude = self._lat;
                    //         value.longitude = self._lng;
                    //         value.address = self._address;

                    //         if (this._isRoleCdFixed) {
                    //             value.roleCd = self._roleCd;
                    //         }
                    //         value.version = self._version;
                    //         delete value.passwordConfirm;
                    //         self.emit('update', { value: value });
                    //     });
                    // } else {
                    if (this._isRoleCdFixed) {
                        value.roleCd = this._roleCd;
                    }
                    value.version = this._version;
                    delete value.passwordConfirm;
                    this.emit('update', { value: value });
                    // }
                } catch (e) {
                    console.error(e);
                }
                return false;
            },

            onDelete: function () {
                console.debug('[削除]ボタンがクリックされました。');
                var value = this.form.get('value');
                value.userId = this._userId;
                value.version = this._version;
                this.emit('remove', { value: value });
            },

            /**
             * ダイアログを初期化する。（更新時）
             */
            initDialog: function (object) {
                this.reset();
                this.initForm(object);
                this.userId.innerHTML = this._userId;
                this.issued.innerHTML = this._issued;
                // 利用者が変更可能な場合はSelect要素、不可な場合は固定文字列を表示
                //            this._isRoleCdFixed = UserInfo.getRoleCd() !== 'R01001';
                //            this.roleCdSelect.set('disabled', this._isRoleCdFixed);
                this.organizationSelector.restoreRefresh();
                this.emergencyOrganization.restoreRefresh();

                if (object.organization.substr(0, 1) === 'D') {
                    this.stationSelector.set('value', object.organization.substr(1));
                } else if (object.organization.substr(0, 1) === 'S') {
                    // 所属組織がsectまで使用している場合は、sectCdからdeptCdを逆引きする
                    Loader.wait(Requester.get('api/organization/sects/' + object.organization.substr(1))
                        .then(lang.hitch(this, function (sect) {
                            this.stationSelector.set('value', sect.deptCd);
                            this.organizationSelector.set('value', object.organization);
                        }))
                    );
                }

                if (object.emergencyOrganization) {
                    var prf = object.emergencyOrganization.substr(0, 1); // 組織の接頭辞
                    this._emergencyOrganization = prf + object.emergencyOrganization;
                    this.emergencyOrganization.set('value', this._emergencyOrganization);
                }

                // 役割コード・更新ボタン・削除ボタン・再発行ボタン・パスワードリセットを無効/非表示に（初期値）
                this.roleCdSelect.set('readOnly', true);
                domStyle.set(this.submitButton.domNode, 'display', 'none');
                domStyle.set(this.deleteButton.domNode, 'display', 'none');
                domStyle.set(this.excangeCodeButtonArea, 'display', 'none');
                domStyle.set(this.mailManageButton.domNode, 'display', 'none');
                domStyle.set(this.outputAccountInfoButton.domNode, 'display', 'none');

                // 役割コード別の表示制御
                var roleCd = UserInfo.getRoleCd();
                switch (roleCd.substr(0, 3)) {
                    case 'R01':
                        // ログインユーザがシステム管理者、全体管理者の場合は、配下のすべてのユーザの更新削除可能
                        if (roleCd === ACL.ADMIN_USER ||
                            (roleCd === ACL.SYSMGR_USER && object.roleCd !== ACL.ADMIN_USER)) {
                            domStyle.set(this.submitButton.domNode, 'display', '');
                            domStyle.set(this.deleteButton.domNode, 'display', '');
                            domStyle.set(this.outputAccountInfoButton.domNode, 'display', '');
                            // ただし、osaka-adm（システム管理者）およびsysmgr01（全体管理者）は、
                            // メンテナンス用ユーザであるためアカウント種類の変更を不可とする
                            // （osaka-admでないシステム管理者、sysmgr01でない全体管理者のアカウント種類は、変更可能）
                            if(this.userId.innerHTML !=='osaka-adm' && this.userId.innerHTML !=='sysmgr01'){
                                this.roleCdSelect.set('readOnly', false);
                            }
                        } else if (roleCd === ACL.SHMGR_USER) {
                            // ログインユーザが市本部管理者の場合、更新不可
                            domStyle.set(this.submitButton.domNode, 'display', 'none');
                            domStyle.set(this.outputAccountInfoButton.domNode, 'display', 'none');
                            // 操作対象が一般・応援職員・自主防災・福祉避難所・施設管理者・下水処理事業者・
                            // 道路管理事業者ユーザの場合のみ、更新可
                            if (object.roleCd === ACL.IPPAN_USER || this._acceptableUser.includes(object.roleCd)) {
                                domStyle.set(this.submitButton.domNode, 'display', '');
                                domStyle.set(this.outputAccountInfoButton.domNode, 'display', '');
                            }
                        } else if (roleCd === ACL.XXMGR_USER) {
                            // ログインユーザが所属管理者の場合
                            // 操作対象が管理者および市本部ユーザ以外の場合、更新可
                            if (object.roleCd.substr(0, 3) !== 'R01' && object.roleCd !== ACL.SHIHONBU_USER) {
                                domStyle.set(this.submitButton.domNode, 'display', '');
                                domStyle.set(this.outputAccountInfoButton.domNode, 'display', '');
                            }
                        }
                        break;
                    case 'R02':
                        // 所属本部ユーザの場合、更新不可
                        this.roleCdSelect.set('readOnly', true);
                        domStyle.set(this.submitButton.domNode, 'display', 'none');
                        domStyle.set(this.outputAccountInfoButton.domNode, 'display', 'none');

                        // 人事室および総務局ユーザの場合、更新可・削除不可
                        if (roleCd === ACL.XXHONBU_USER &&
                            (UserInfo.getOrganization().deptCd === config.dept.humanResourceDeptCd ||
                                UserInfo.getOrganization().deptCd === config.dept.soumuDeptCd)) {
                            domStyle.set(this.submitButton.domNode, 'display', '');
                            domStyle.set(this.outputAccountInfoButton.domNode, 'display', '');
                        }
                        break;
                    case 'R03':
                        // 一般職員ユーザの場合、（自ユーザの）更新可
                        domStyle.set(this.submitButton.domNode, 'display', '');
                        domStyle.set(this.outputAccountInfoButton.domNode, 'display', '');
                        break;
                    default:
                        break;
                }
                // 操作対象が一般職員・応援職員・自主防災・福祉避難所・施設管理者・下水処理事業者・
                // 道路管理事業者・参照権限ユーザの場合（ROLE_CD=R03xxxのユーザ）、再発行ボタンを表示
                // if (object.roleCd === ACL.IPPAN_USER) {
                if (object.roleCd.substr(0, 3) === 'R03') {
                    domStyle.set(this.excangeCodeButtonArea, 'display', '');
                }

                // 操作対象が一般職員・応援職員・自主防災・福祉避難所・施設管理者・下水処理事業者・
                // 道路管理事業者の場合、メール配信条件管理ボタンを表示
                if (object.roleCd === ACL.IPPAN_USER || this._acceptableUser.includes(object.roleCd)) {
                    domStyle.set(this.mailManageButton.domNode, 'display', '');
                }
            },

            // 役割セレクトボックスの初期化を行う
            initSelectBox: function () {
                var promise = Requester.get('/api/roles');
                return Loader.wait(promise).then(lang.hitch(this, function (data) {
                    // セレクトボックスに設定するデータの配列を作成
                    var newOptions = [];
                    // サーバーレスポンスの各要素をselectへ追加
                    data.items.forEach(function (object) {
                        newOptions.push({
                            label: object.roleName,
                            value: object.roleCd
                        });
                    });
                    this.roleCdSelect.set('options', newOptions);
                }));
            },

            /**
             * パスワードを生成し、アカウント情報を出力する
             */
            onOutputAccountInfoButtonClick: function () {

                var oMailAddress = !this._officialMailAddress ? '（未登録）' : this._officialMailAddress;
                var pMailAddress = !this._personalMailAddress ? '（未登録）' : this._personalMailAddress;
                // 個人用メールアドレスがマスクされている場合はダイアログ上でもマスクする
                if (pMailAddress) {
                    pMailAddress = this.personalMailAddress.get('value') === '***' ? '***（非表示）' : pMailAddress;
                }

                var message = '「アカウント情報出力（パスワードリセット）」ボタンをクリックしました。<br>';
                message += '・ユーザID： <b>' + this._userId + '</b> のログインパスワードをリセットします。<br>';
                message += '・アカウント情報ファイルをダウンロードします。<br>';
                message += '・以下の登録済メールアドレス宛に新しいログインパスワードを送信します。<br>';
                message += '（メールアドレスが未登録の場合はメール送信しません。アカウント情報ファイルで確認してください。）<br><br>';
                message += '&nbsp;&nbsp;庁内アドレス：' + oMailAddress + '<br>';
                message += '&nbsp;&nbsp;個人アドレス：' + pMailAddress + '<br><br>';
                message += '※メールアドレスを追加で登録または修正する場合は、<br>';
                message += '&nbsp;&nbsp;先に「更新」ボタンでメールアドレスを更新してから操作してください。<br><br>';
                message += 'よろしいですか？';

                console.debug('[アカウント情報出力]ボタンがクリックされました。');
                var value = this.form.get('value');
                value.userId = this._userId;
                value.userName = this._userName;
                value.userNameKana = this._userNameKana;
                value.officialPosition = this._officialPosition;
                value.officialPositionName = this._officialPositionName;
                value.supportStation = this._supportStation;
                value.emergencyOrganization = this._emergencyOrganization;
                value.emergencyOrganizationName = this._emergencyOrganizationName;
                value.mobilizationSection = this._mobilizationSection;
                value.mobilizationType = this._mobilizationType;
                value.officialMailAddress = this._officialMailAddress;
                value.personalMailAddress = this._personalMailAddress;
                value.account = this._account;
                value.note = this._note;
                value.version = this._version;
                value.message = message;

                // パスワード生成
                this._userPassword = this.generatePassword();
                value.userPassword = this._userPassword; // 生成したパスワード
                delete value.passwordConfirm;
                delete value.station;
                value.organization = this._organization;
                value.exchangeCode = this._exchangeCode;
                this.emit('reset-password', { value: value });
            },
            /**
             * iOSアプリ引き換えコードを発行しメール送信する。
             */
            onIssueExcangeCodeButtonClick: function () {

                if (UserInfo.isTrainingFlg()) {
                    this.infoDialog = new InfoDialog({
                        title: 'エラー',
                        content: '引き換えコードは本番モードでログインしてから「再発行」してください。'
                    });
                    this.infoDialog.show();
                    this.infoDialog = null;
                    return false;
                }
                // メールアドレスはuserIDで検索したM_USERの値でチェック。画面に入力されただけの値は使用しない。
                var oMailAddress = !this._officialMailAddress ? '（未登録）' : this._officialMailAddress;
                var pMailAddress = !this._personalMailAddress ? '（未登録）' : this._personalMailAddress;
                // 個人用メールアドレスがマスクされている場合はダイアログ上でもマスクする
                if (pMailAddress) {
                    pMailAddress = this.personalMailAddress.get('value') === '***' ? '***（非表示）' : pMailAddress;
                }

                var message = '以下の登録済メールアドレス宛にiOSアプリ引き換えコードを送信します。<br>';
                message += '（メールアドレスが未登録の場合はメール送信しません。「アカウント情報出力」から確認してください。）<br><br>';
                message += '&nbsp;庁内アドレス：' + oMailAddress + '<br>';
                message += '&nbsp;個人アドレス：' + pMailAddress + '<br><br>';
                message += '※メールアドレスを追加で登録または修正する場合は、<br>';
                message += '&nbsp;&nbsp;先に「更新」ボタンでメールアドレスを更新してから「再発行」してください。';

                console.debug('[再発行]ボタンがクリックされました。');
                var value = this.form.get('value');
                value.userId = this._userId;
                value.version = this._version;
                value.message = message;
                this.emit('issue', { value: value });
            },
            /**
             * パスワード文字列を生成する
             */
            generatePassword: function () {
                var LENGTH = 8; // パスワード文字列長
                // パスワード生成元となる文字（'l'と'o'を除く英数字）
                var SOURCE = 'abcdefghijkmnpqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
                var result = '';
                for (var i = 0; i < LENGTH; i++) {
                    result += SOURCE[Math.floor(Math.random() * SOURCE.length)];
                }
                return result;
            },
            /**
             * 住所の位置を地図上にポイントします。
             */
            fillAddLatLng: function () {
                var address = this.address.value;
                var lat = this.latitude.value;
                var lng = this.longitude.value;
                var dfd = null;
                // 住所入力がある場合
                if (address && (!lat || !lng)) {
                    // 緯度経度に入力がない場合
                    // 住所からgeocoding
                    // var prefIndex = address.indexOf('県');
                    // 県名がない場合、他県の地名を探し出す可能性があるので県名をつける
                    // if(prefIndex === -1){
                    //     address = config.municInfo.prefName + address;
                    // }
                    console.debug('start geocoding');
                    if (this._geoService === null) {
                        this._geoService = new GeoService();
                    }
                    dfd = this._geoService.geocode(address).then(lang.hitch(this, function (results) {
                        if (results.length > 0) {
                            this._lat = results[0].latlng.lat;
                            this._lng = results[0].latlng.lng;
                            this._address = address;
                        } else {
                            console.debug('address is not correct');
                            dfd = this.setDefAddLatLng();
                        }
                        console.debug('end geocoding (lat:' + this._lat + ', lng:' + this._lng + ')');
                    })
                    );
                } else {
                    // 住所入力がない場合
                    if (lat && lng) {
                        // 緯度経度入力がある場合、住所を取得
                        console.debug('start reverse geocoding');

                        if (this._geoService === null) {
                            this._geoService = new GeoService();
                        }
                        dfd = this._geoService.reverseGeocode(leaflet.latLng({
                            lat: lat,
                            lng: lng
                        })).then(lang.hitch(this, function (res) {
                            if (res.address.Address) {
                                this._address = res.address.Address;
                                this._lat = lat;
                                this._lng = lng;
                            } else {
                                console.debug('住所を取得できませんでした。');
                                dfd = this.setDefAddLatLng();
                            }
                            console.debug('end reverse geocoding (address: ' +
                                this._address + ')');
                        }), lang.hitch(this, function () {
                            console.debug('住所を取得できませんでした。');
                            dfd = this.setDefAddLatLng();
                        }));
                    }
                    // 緯度経度の入力がない場合、デフォルトを使用
                    else {
                        dfd = this.setDefAddLatLng();
                    }
                }
                return dfd;
            },

            setDefAddLatLng: function () {
                return Requester.get('/api/municipalities/' + UserInfo.getSelectedMunicipalityCd(), {
                    headers: { 'Content-Type': 'application/json; charset=utf-8' },
                    handleAs: 'json',
                    preventCache: true
                }).then(function (item) {
                    this._lat = item.latitude;
                    this._lng = item.longitude;
                    this._address = item.address;
                }, function (error) {
                    console.log(error);
                });
            },

            /**
             * 利用者用メール配信管理ダイアログを表示する。
             */
            showMailDialog: function () {
                var dialog = this.userMailDialog;
                var page = dialog.getChildren()[0];
                page.initDialog(this._userId);
                dialog.show();
            }
        });
});
