/**
* 被害状況の基底モジュール。
* @module app/damage/_DamageReportPageBase
*/
define([
    'module',
    'app/model/LayerStore',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'dojo/dom-class',
    'dojo/dom-construct',
    'dojo/dom-geometry',
    'dojo/dom-style',
    'dojo/json',
    'dojo/on',
    'dojo/request/iframe',
    'dojo/topic',
    'dojo/window',
    'dstore/Rest',
    'dijit/Menu',
    'dijit/MenuItem',
    'dijit/popup',
    'dijit/TooltipDialog',
    'dijit/registry',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/map/IdisMap',
    'idis/model/UserInfo',
    'app/model/DisasterInfo',
    'idis/service/GeoService',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/IdisDialog',
    'idis/view/draw/_DrawUtil',
    'idis/view/page/_PageBase',
    'idis/view/Loader',
    'leaflet',
    '../config',
    'app/map/baselayer/BaseLayerPane',
    // 以下、変数として受け取らないモジュール
    'dijit/Dialog',
    'dijit/form/CheckBox',
    'dijit/form/Form',
    'dijit/form/RadioButton',
    'dijit/form/Select',
    'dijit/form/Textarea',
    'dijit/form/TextBox',
    'dijit/form/ValidationTextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dojox/form/Uploader',
    'dojox/layout/FloatingPane',
    'idis/view/form/Button',
    'idis/view/form/RadioGroup',
    '../view/form/LayerDirectorySelector',
    '../view/form/DisasterSelector',
    '../view/form/OrganizationSelector',
    '../view/form/MunicipalitySelector',
], function (module, LayerStore, array, declare, lang, locale, domClass, domConstruct, domGeometry, domStyle,
    json, on, iframe, topic, winUtils, Rest, Menu, MenuItem, popup, TooltipDialog, registry,
    Locator, Router, IdisMap, UserInfo, DisasterInfo, GeoService, Requester, DialogChain, IdisDialog, DrawUtil,
    _PageBase, Loader, leaflet, config, BaseLayerPane) {

    /**
     * 被害状況新規登録・詳細・続報登録画面画面。
     * @class _DamageReportPageBase
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/damage/_DamageReportPageBase~_DamageReportPageBase# */ {

            // 県の市町村コード
            PREF_MUNICIPALITY_CODE: config.municInfo.prefMunicCd,
            // 市の市町村コード
            CITY_MUNICIPALITY_CODE: config.municInfo.cityMunicCd,
            // 政令指定市の中心区の市町村コード
            CHUOUKU_MUNICIPALITY_CODE: config.municInfo.defaultMunicCd,

            MUNICIPALITY_SELECTED: 'app/view/form/DamageMunicipalitySelector::selected',
            MUNICIPALITY_RELEASED: 'app/view/form/DamageMunicipalitySelector::released',

            // デフォルトの地図位置・ズーム値
            INIT_LATLNG: { lat: config.map.latitude, lng: config.map.longitude },
            MAP_ZOOM: 11,

            // 定時報告認可CD
            SCHEDULED_REPORT_AUTH: 'F05009',

            //イベント破棄用
            _events: [],

            /**
            * popupイベントを保持する
            * @private
            */
            _downloadEvts: [],

            /**
             * 背景地図ダイアログに対する参照
             * @type {module:idis/view/dialog/IdisDialog~IdisDialog}
             * @private
             */
            _baseLayerDialog: null,

            /**
             * 災害ID
             * @private
             */
            _disasterId: null,

            /**
             * 市町村コード
             * @private
             */
            _municipalityCd: null,
            _municipalityCdList: null,

            //地図中に提示する被害状況のレイヤー
            layerGroup: null,
            layerGroupForSubMap: null,

            /**
             * constructor
             */
            constructor: function () {
                console.debug('_DamageReportPageBase:constructor()');

                this.chain = DialogChain.get(this);

                // 作図のポップアップイベントの保持
                this._downloadEvts = [];

                // 災害IDをDisasterInfoから取得
                this._disasterId = DisasterInfo.getDisasterId();
                if (!this._disasterId) {
                    this._disasterId = 1;
                    console.warn('災害IDが設定されていません。');
                }

                this._events = [];

                console.debug('現在のログインユーザID：' + UserInfo.getId());
                console.debug('現在の災害ID：' + DisasterInfo.getDisasterId());
            },

            /**
             * 背景地図ダイアログを表示する。
             */
            showBaseLayerDialog: function () {
                if (!this._baseLayerDialog) {
                    // 初回呼び出し時にインスタンス生成
                    this._baseLayerDialog = new IdisDialog({
                        noUnderlay: true,
                        title: '背景地図',
                        content: new BaseLayerPane({ map: this.map })
                    });
                    // 画面が破棄された際に連れて行く
                    this.own(this._baseLayerDialog);
                }
                this._baseLayerDialog.show();
            },

            makeDamageMarker: function (item) {
                var lat = item.latitude;
                var lng = item.longitude;
                var lifeType = {
                    'elec1': item.elecMax,
                    'elec2': item.elecCurr,
                    'water1': item.watRedMax,
                    'water2': item.watRedCurr,
                    'water3': item.watFailMax,
                    'water4': item.watFailCurr,
                    'gas1': item.gusMax,
                    'gas2': item.gusCurr,
                    'phone1': item.telMax,
                    'phone2': item.telCurr
                };
                var lifeTypeNum = {};
                var type;
                var beforeKey = null;
                if (!lat || !lng) {
                    return false;
                }

                //レコードの値から、imgファイル名上の値に変換する
                var urgencyType = '1';
                if (item.urgencyType !== null && item.urgencyType !== '') {
                    urgencyType = item.urgencyType;
                }
                var hldStatus = '01';
                if (item.hldStatus !== null && item.hldStatus !== '') {
                    if (item.hldStatus === '1' || item.hldStatus === '2') {
                        hldStatus = '02';
                    } else if (item.hldStatus === '3') {
                        hldStatus = '03';
                    }
                }
                var humanDamageFlg = item.humanDamageFlg;

                //メインアイコン
                var mainIcon = '/data/images/draw/icon/080.png';
                var html = [];
                var leftPosition = 0;
                if (item.damageType && (item.urgencyType || item.urgencyType === '0') && item.damageTypeList) {
                    for (var key in lifeType) {
                        if (key) {
                            //lifeType[key] に1以上の数字が入っている場合
                            if (!this.isEmpty(lifeType[key])) {
                                //最初のkeyの場合
                                if (key === 'elec1') {
                                    lifeTypeNum[key] = lifeType[key];
                                }
                                // 2つ目以降のkeyの場合
                                // beforeKeyとkeyが同じ && 1つ前のlifeType[beforeKey]に値が入っていない場合
                                else if (key.slice(0, -1) === beforeKey.slice(0, -1) &&
                                    this.isEmpty(lifeType[beforeKey])) {
                                    lifeTypeNum[key] = lifeType[key];
                                }
                                //beforeKeyとkeyが異なる場合
                                else if (key.slice(0, -1) !== beforeKey.slice(0, -1)) {
                                    lifeTypeNum[key] = lifeType[key];
                                }
                            }
                            beforeKey = key;
                        }
                    }
                    array.forEach(item.damageTypeList.split(','), function (damageType, i) {
                        //被害種別がライフラインの場合、被害箇所のiconを設定する
                        if (damageType === '02' && Object.keys(lifeTypeNum).length === 1) {
                            for (var key2 in lifeTypeNum) {
                                if (key2) {
                                    type = key2.slice(0, -1);
                                    mainIcon = '/images/damage/type_' + damageType + '_' +
                                        type + '_urg_' + item.urgencyType + '.png';
                                }
                            }
                        }
                        else {
                            mainIcon = '/images/damage/type_' + damageType + '_urg_' + item.urgencyType + '.png';
                        }
                        leftPosition = 20 * i;
                        html += '<img src="' + mainIcon + '" height="35px" width="35px" style="position:absolute;top:' +
                            0 + 'px;left:' + leftPosition + 'px;">';
                    });

                }
                // if(damageType){
                //     mainIcon = '/images/damage/type_' + damageType + '_urg_' + urgencyType + '.png';
                // }

                //人的被害アイコン
                var humanDamageIcon = '/images/damage/hmDmg_0.png';
                var leftFlgPosition = leftPosition + 18;
                var leftStatusPosition = leftPosition + 20;
                var iconSize = 20;
                if (humanDamageFlg !== null) {
                    humanDamageIcon = '/images/damage/hmDmg_' + humanDamageFlg + '.png';
                    iconSize = (humanDamageFlg === '0') ? 20 : 12;
                }
                html += '<img src="' + humanDamageIcon + '" height="' + iconSize + 'px" width="' + iconSize + 'px"';
                html += ' style="position:absolute;top:15px;left:' + leftFlgPosition + 'px;margin:2px">';

                //対応状況アイコン
                var hldStatusIcon = '/images/damage/status_' + hldStatus + '.png';
                html += '<img src="' + hldStatusIcon + '" height="12px" width="12px"';
                html += ' style="position:absolute;top:-4px;left:' + leftStatusPosition + 'px;">';

                // var damageTypeList = item.damageTypeList.split(',');
                // var anchorX = this.calAnchorX(cnt, damageTypeList);
                // var anchorY = this.calAnchorY(cnt, damageTypeList);

                var divIcon = leaflet.divIcon({
                    html: html,
                    className: 'damage-point',
                    iconSize: [0, 0],
                    iconAnchor: [3, 3]
                });

                var marker = leaflet.marker([lat, lng], {
                    icon: divIcon
                });

                return marker;
            },

            isEmpty: function (str) {
                if (str === void 0) {
                    return true;
                }
                if (str === null) {
                    return true;
                }
                if (str === '') {
                    return true;
                }
                if (str === '0') {
                    return true;
                }
                return false;
            },

            /**
             * 行政区変更時のイベントを設定
             */
            controlDamageDetailContents: function () {
                if (registry.byId('municipalityCd')) {
                    registry.byId('municipalityCd').on('change', lang.hitch(this, function (value) {
                        this.changeMunicipalityDetailContents(value);
                    }));
                }
            },

            setMunicipalityCd: function (data) {
                this._municipalityCd = data.mainTypeIds[0]; //FIXME: 一旦DBに送るのはメインの行政区のみ
                this._municipalityCdList = data.mainTypeIds.join(',');
            },

            releaseMunicipalityCd: function () {
                this._municipalityCd = null;
                this._municipalityCdList = null;
            },

            calAnchorX: function (cnt, damageTypeList) {
                var anchorX = 25;
                switch (damageTypeList.length) {
                    case 2:
                        if (cnt === 0) {
                            anchorX = 10;
                        } else if (cnt === 1) {
                            anchorX = 57;
                        }
                        break;
                    case 3:
                        if (cnt === 0) {
                            anchorX = 30;
                        } else if (cnt === 1) {
                            anchorX = 33;
                        } else if (cnt === 2) {
                            anchorX = 87;
                        }
                        break;
                    default:
                        break;
                }
                return anchorX;
            },

            calAnchorY: function (cnt, damageTypeList) {
                var anchorY = 10;
                switch (damageTypeList.length) {
                    case 2:
                        if (cnt === 1) {
                            anchorY = 26;
                        }
                        break;
                    case 3:
                        if (cnt === 1 || cnt === 2) {
                            anchorY = 40;
                        }
                        break;
                    default:
                        break;
                }
                return anchorY;
            },

            /**
             * 数値情報の全ての項目が0の場合はtrue、1項目でも1以上の数値の場合はfalseを返す
             */
            checkNumberData: function(numberData){
                var checkNumberDataFlg = true;
                for( var key in numberData ){
                    if( numberData.hasOwnProperty(key) ){
                        if(Array.isArray(numberData[key]) || key === 'humanDamageList'){
                            continue;
                        }
                        if( numberData[key] && numberData[key] !== '0' ){
                            checkNumberDataFlg = false;
                            break;
                        }
                    }
                }
                return checkNumberDataFlg;
            },

            /**
             * 数値情報に1以上の値が入力された場合に大項目のラベル表示を編集・設定する
             */
            setNumberDataLabel: function(numberData){
                var humanDamageNumberList = [numberData.deadNumI, numberData.unknownNumI, numberData.seriousNumI, numberData.mildNumI];
                var homeNumberList = [numberData.fullDestlHmI, numberData.fullDestlHhI, numberData.fullDestlHrI, numberData.halfDestlHmI, numberData.halfDestlHhI, numberData.halfDestlHrI, numberData.partDestlHmI, numberData.partDestlHhI, numberData.partDestlHrI, numberData.fldAbvHmI, numberData.fldAbvHhI, numberData.fldAbvHrI, numberData.fldBlwHmI, numberData.fldBlwHhI, numberData.fldBlwHrI];
                var nHomeNumberList = [numberData.pubDestlI, numberData.nhomeOtrI];
                var otherDamageNumberList = [numberData.eduDamageI, numberData.hspDamageI, numberData.rodDamageI, numberData.brgDamageI, numberData.rivrDamageI, numberData.sewDamageI, numberData.hbrDamageI, numberData.sabDamageI, numberData.clnDamageI, numberData.trnDamageI, numberData.shpDamageI, numberData.watFailCurrI, numberData.telCurrI, numberData.elecCurrI, numberData.gusCurrI, numberData.blkDamageI, numberData.paddyFlowI, numberData.paddyFloodI, numberData.farmFlowI, numberData.farmFloodI, numberData.rockDamageI];
                var fireNumberList = [numberData.bldDamageI, numberData.dangerNumI, numberData.otherNumI];
                var disasterNumberList = [numberData.disasterHhI, numberData.disasterHrI];
                var financialDamageNumberList = [numberData.financialDamagePe, numberData.financialDamageAf, numberData.financialDamagePc, numberData.financialDamagePo];
                var otherFinancialDamageNumberList = [numberData.financialDamageAd, numberData.financialDamageFd, numberData.financialDamageLd, numberData.financialDamageMd, numberData.financialDamageBd, numberData.financialDamageOd];
                var fireFighterNumberList = [numberData.firefiterNumI, numberData.firebrigadeNumI];

                var numberDataList = [humanDamageNumberList, homeNumberList, nHomeNumberList, otherDamageNumberList, fireNumberList, disasterNumberList, financialDamageNumberList, otherFinancialDamageNumberList, fireFighterNumberList];

                //各大項目の数値が0か1以上かをチェック
                var numberDataLabel = [];
                numberDataList.forEach(lang.hitch(this,function(listContent, index){
                    if(this.checkNumberData(listContent) === false){
                        var labelName;
                        var indexNum = index;
                        if(indexNum === 0){
                            labelName = "人的被害";
                        }else if(indexNum === 1){
                            labelName = "住家被害";
                        }else if(indexNum === 2){
                            labelName = "非住家被害";
                        }else if(indexNum === 3){
                            labelName = "その他被害";
                        }else if(indexNum === 4){
                            labelName = "火災発生";
                        }else if(indexNum === 5){
                            labelName = "り災";
                        }else if(indexNum === 6){
                            labelName = "公共建物被害額";
                        }else if(indexNum === 7){
                            labelName = "その他被害額";
                        }else if(indexNum === 8){
                            labelName = "消防";
                        }
                    
                        numberDataLabel.push(labelName);
                    }
                }));
                this.numberEntered.innerHTML = numberDataLabel.join('、');
            },

            /**
             * 被害状況概況一覧画面へ遷移する。
             */
            onDamageReportAdminPageLinkClick: function (evt) {
                this._removeEvents();
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                Router.moveTo('report/admin');
            },

            /**
             * 被害状況一覧画面へ遷移する。
             */
            onDamageReportPageLinkClick: function (evt) {
                this._removeEvents();
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                Router.moveTo('report');
            },


            /**
             * 定時報告画面を表示
             */
            showScheduledReportPage: function () {
                this._removeEvents();
                // 定時報告画面へ遷移
                Router.moveTo('scheduledReport');
            },



            _removeEvents: function () {
                this._events.forEach(function (event) {
                    event.remove();
                });
            }
        });
});
