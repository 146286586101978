/**
 * 招集メール配信ダイアログ用モジュール。
 * @module app/convocation/view/mail/ConvoDeterrenceDialog
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'idis/control/Router',
    'dojo/text!./templates/ConvoDeterrenceDialog.html', // テンプレート文字列
    'idis/store/IdisRest',
    'idis/view/page/_PageBase', // 共通基底クラス
    'idis/view/Loader',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'app/model/ConvoMail',
    // 以下、変数で受けないモジュール
    'app/convocation/view/mail/ConvocationMailAdminPage',
    'dijit/Dialog',
    'idis/view/form/Button',
    'app/convocation/view/mail/ConvoSendAutoMailGrid'
], function(module, declare, lang, Router, template, IdisRest, _PageBase,
    Loader, Requester, DialogChain, ConvoMail) {
    /**
     * 動員抑止設定ダイアログ。
     * @class 動員抑止設定ダイアログ
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/convocation/view/mail/ConvoDeterrenceDialog~ConvoDeterrenceDialog# */
        {
            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--convocation',

            /**
             * 変数の初期化。
             */
            constructor: function() {
                this.chain = DialogChain.get(this);
            },

            initDialog: function() {
                //現在の動員抑止設定をダイアログ内プルダウンにセットする
                ConvoMail.getSuppressConvoInfo().then(lang.hitch(this, function(deterrenceType) {
                    this.deterrenceType = deterrenceType;
                    deterrenceType.forEach(lang.hitch(this, function(item){
                        var firstChar = item.substring(0,1);
                        if (firstChar === "1") { 
                            this.earthquakeDeterrence.set('value', item);
                        } else if (firstChar === "2") {
                            this.tsunamiDeterrence.set('value', item);
                        }
                    }));
                }));
            },

            onSubmit: function(){
                //動員抑止設定を登録更新する
                try{
                    var sendData = this.form.get('value');
                    this.emit('send', {sendData: sendData});
                } catch (e) {
                    console.error(e);
                }
            },
        });
});
